import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface TextModalData {
  title: string;
  description: string;
}
@Component({
  selector: 'lib-pxl-text-modal',
  templateUrl: './text-modal.component.html',
  styleUrls: ['./text-modal.component.scss'],
})
export class TextModalComponent {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<TextModalComponent>, @Inject(MAT_DIALOG_DATA) public modalData: TextModalData) {}
}
