import { Injectable } from '@angular/core';
import { NoteDepensesProjet } from '@shared-ui';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProjetInfoDepensesSharedService {
  private isSyntheseFormValid = new BehaviorSubject<boolean>(null);

  private isShowing = new BehaviorSubject<boolean>(null);

  commentaireDepense = new BehaviorSubject<{
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number };
    columnName: string;
  }>(null);

  private commentaireToSave = new BehaviorSubject<{
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number };
    columnName: string;
  }>(null);

  setIsSyntheseFormValid(data: boolean) {
    this.isSyntheseFormValid.next(data);
  }

  getIsSyntheseFormValid() {
    return this.isSyntheseFormValid.asObservable();
  }

  setIsShowing(data: boolean) {
    this.isShowing.next(data);
  }

  getIsShowing() {
    return this.isShowing.asObservable();
  }

  setCommentaireToDisplay(param: {
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number };
    columnName: string;
  }) {
    this.commentaireDepense.next(param);
  }

  getCommentaireToDisplay() {
    return this.commentaireDepense.asObservable();
  }

  getCommentaireToSave() {
    return this.commentaireToSave.asObservable();
  }
  setCommentaireToSave(param: { index: number; depenseData: { note: NoteDepensesProjet | undefined; value: number }; columnName: string }) {
    this.commentaireToSave.next(param);
  }
}
