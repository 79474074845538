import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnqueteRadioToggleComponent } from './enquete-radio-toggle.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [EnqueteRadioToggleComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [EnqueteRadioToggleComponent],
})
export class EnqueteRadioToggleModule {}
