<div
  id="card-info"
  class="card card--message"
  (click)="clickEmitter.emit()"
  [ngClass]="{ 'card--alert': isAlert, card__small: isSmallCard }"
  [class.card--inactive]="isInactive"
>
  <div class="title-container">
    <lib-pxl-icon
      *ngIf="showCheckmark && !showCheckmarkRight"
      class="green-checkmark"
      icon="icon-action-check-circle"
      aria-hidden="true"
    ></lib-pxl-icon>
    <div class="card__title">
      {{ inputText }}
      <div *ngIf="descriptionText" class="card--message">{{ descriptionText }}</div>
    </div>

    <lib-pxl-icon
      *ngIf="showCheckmark && showCheckmarkRight"
      class="green-checkmark"
      icon="icon-action-check-circle"
      aria-hidden="true"
    ></lib-pxl-icon>
  </div>
  <lib-pxl-icon
    class="card__arrow"
    icon="icon-arrow-arrow-forward-ios"
    aria-hidden="true"
    *ngIf="!isInactive && !isReadOnly"
  ></lib-pxl-icon>
  <lib-pxl-icon class="card__arrow" icon="icon-visibility" aria-hidden="true" *ngIf="isReadOnly"></lib-pxl-icon>
</div>
