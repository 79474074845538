import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InformationBancaireModalComponent } from './information-bancaire-modal.component';
import { SharedUiModule } from '@shared-ui';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';

@NgModule({
  declarations: [InformationBancaireModalComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, SharedModule, SharedUiModule],
})
export class InformationBancaireModalModule {}
