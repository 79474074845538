import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function developpementDurableValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.get('radioToggle').value === false) {
      return null;
    }

    const selectSize: number =
      control.get('energiesRenouvelables').value?.length +
      control.get('gestionPollution').value?.length +
      control.get('optimisationConsommations').value?.length;

    return selectSize === 0 ? { selectSize } : null;
  };
}
