import { enumKeys } from '../../utils/enum-utils';

export enum EnumCategorieStructure {
  PE = 'PE',
  ME = 'ME',
  GE = 'GE',
  LABO = 'LABO/NON ECO',
}

export namespace EnumCategorieStructure {
  export function toString(key: string): string | undefined {
    return toMap().get(key);
  }

  export function all(): EnumCategorieStructure[] {
    return enumKeys(EnumCategorieStructure) as unknown as EnumCategorieStructure[];
  }

  export function toMap(): Map<string, string> {
    const map = new Map<string, string>();
    enumKeys(EnumCategorieStructure).forEach(key => map.set(key, EnumCategorieStructure[key].toString()));
    return map;
  }

  export function toKey(value: string): string | null {
    for (const entry of toMap()) {
      if (entry[1] === value) {
        return entry[0];
      }
    }
    return null;
  }
}
