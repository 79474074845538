import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { ProjetConsortiumBudgetComponent } from './projet-consortium-budget.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [ProjetConsortiumBudgetComponent],
  imports: [CommonModule, MaterialModule, SharedModule, SharedUiModule, MatSlideToggleModule],
})
export class ProjetConsortiumBudgetModule {}
