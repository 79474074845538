import { Injectable } from '@angular/core';
import {
  EnumProcedureSignatureStatut,
  EnumStatutSignature,
  EnumTypeSignataire,
  ProcedureSignatureStructure,
  SyntheseContractualisationProjet,
  SyntheseContractualisationStructure,
  Utilisateur,
} from '@shared-ui';
import { catchError, combineLatest, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { StructureService } from './structure.service';
import { UserService } from './user.service';
import { HttpResponse } from '@angular/common/http';
import { ProjetService } from './projet.service';

@Injectable()
export class ContractualisationService {
  syntheseStructures$: Observable<SyntheseContractualisationStructure[]>;

  /**
   * TODO : Cleaner le constructor quand le back filtrera les synthèses, voir la fonction filterSynthesesForUser
   * @Tarik s'occupe du filtre coté back, à voir avec lui pour confirmer si c'est fait ou non
   */
  constructor(private structureService: StructureService, private userService: UserService, private projetService: ProjetService) {
    this.syntheseStructures$ = combineLatest([
      this.structureService.loadSyntheseContractualisationStructuresSubject(),
      this.userService.getUserObservable(),
    ]).pipe(
      map(([response, user]: [SyntheseContractualisationStructure[], any]) => {
        const syntheses = response || [];
        const filteredSyntheses = this.filterSynthesesForUser(syntheses, user);
        return filteredSyntheses;
      }),
      shareReplay(1),
      catchError(() => of([]))
    );
  }

  /**
   * TODO : Cette fonction est utilisée temporairement le temps que le back fasse le filtre, à enlever plus tard et cleaner le constructor
   * @Tarik s'occupe du filtre coté back, à voir avec lui pour confirmer si c'est fait ou non
   */
  private filterSynthesesForUser(
    syntheses: SyntheseContractualisationStructure[],
    user: Utilisateur
  ): SyntheseContractualisationStructure[] {
    if (!user) return [];
    return syntheses
      .map(synthese => {
        const procedures = synthese.proceduresSignatures.filter(procedure => {
          return procedure.signataires.some(signataire => signataire.email === user?.emailAddress);
        });
        return { ...synthese, proceduresSignatures: procedures };
      })
      .filter(synthese => synthese.proceduresSignatures.length > 0);
  }

  canUserSeeContractPage(): Observable<boolean> {
    return combineLatest([
      this.userService.getUserObservable(),
      this.hasSignataireInterneSignedAtLeastOneContract(),
      this.syntheseStructures$,
    ]).pipe(switchMap(([user, hasSigned, syntheses]) => this.checkUserAccessToSyntheses(user, hasSigned, syntheses)));
  }

  canUserSeeSynthese(synthese: SyntheseContractualisationStructure): Observable<boolean> {
    return combineLatest([this.userService.getUserObservable(), this.hasSignataireInterneSignedContractOfStructure(synthese.id)]).pipe(
      switchMap(([user, hasSigned]) => this.checkUserAccessToSyntheses(user, hasSigned, [synthese]))
    );
  }

  isUserSuiveurExterneInCurrentProject(): Observable<boolean> {
    return combineLatest([this.syntheseStructures$, this.userService.getUserObservable()]).pipe(
      map(([syntheses, user]) => {
        if (!syntheses) return false;
        return syntheses.some(synthese => {
          const procedures = this.getLastProcedure(synthese.proceduresSignatures);
          return procedures.some(procedure => {
            return procedure.signataires.some(signataire => {
              return signataire.email === user?.emailAddress && signataire.type === EnumTypeSignataire.SUIVEUR_EXTERNE;
            });
          });
        });
      })
    );
  }

  private hasSignataireInterneSignedContractOfStructure(structureId: string): Observable<boolean> {
    return this.syntheseStructures$.pipe(
      map(syntheses => {
        const synthese = syntheses.find(s => s.id === structureId);
        return synthese ? this.hasSignataireInterneSigned(this.getLastProcedure(synthese.proceduresSignatures)) : false;
      })
    );
  }

  private hasSignataireInterneSignedAtLeastOneContract(): Observable<boolean> {
    return this.syntheseStructures$.pipe(
      map(syntheses => syntheses?.some(synthese => this.hasSignataireInterneSigned(this.getLastProcedure(synthese.proceduresSignatures))))
    );
  }

  private checkUserAccessToSyntheses(
    user: Utilisateur,
    hasSigned: boolean,
    syntheses: SyntheseContractualisationStructure[]
  ): Observable<boolean> {
    if (!user) return of(false);
    if (hasSigned) return of(true);
    if (this.isUserInActiveProcedure(user, syntheses)) return of(true);
    return of(this.isUserSignataireExterneOnHistorizedProcedures(user, syntheses));
  }

  private isUserSignataireExterneOnHistorizedProcedures(user: Utilisateur, syntheses: SyntheseContractualisationStructure[]): boolean {
    return syntheses?.some(synthese => {
      const procedures =
        synthese.proceduresSignatures?.filter(procedure => {
          return (
            procedure.statut !== EnumProcedureSignatureStatut.EN_COURS &&
            procedure.statut !== EnumProcedureSignatureStatut.TERMINEE &&
            procedure.signataires.some(
              signataire => signataire.email === user?.emailAddress && signataire.type === EnumTypeSignataire.SIGNATAIRE_EXTERNE
            )
          );
        }) || [];
      return procedures.length;
    });
  }

  private hasSignataireInterneSigned(procedures: ProcedureSignatureStructure[]): boolean {
    return procedures?.some(procedure => {
      const signataireInterne = procedure.signataires.find(signataire => signataire.type === EnumTypeSignataire.SIGNATAIRE_INTERNE);
      return signataireInterne?.signature?.statut === EnumStatutSignature.TERMINEE;
    });
  }

  private isUserInActiveProcedure(user: any, syntheses: SyntheseContractualisationStructure[]): boolean {
    return syntheses?.some(synthese => {
      const procedures = this.getLastProcedure(synthese.proceduresSignatures);
      return procedures?.some(procedure => {
        return procedure.signataires?.some(signataire => {
          return user?.emailAddress === signataire.email;
        });
      });
    });
  }

  private getLastProcedure(procedures: ProcedureSignatureStructure[]): ProcedureSignatureStructure[] {
    return (
      procedures?.filter(procedure =>
        [EnumProcedureSignatureStatut.EN_COURS, EnumProcedureSignatureStatut.TERMINEE].includes(procedure.statut)
      ) || []
    );
  }

  getSynthese(projetId: string): Observable<HttpResponse<SyntheseContractualisationProjet>> {
    return this.projetService.getSynthese(projetId);
  }

  getSynthesesObservable() {
    return this.syntheseStructures$;
  }
}
