import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { StepInformationStructureModule } from '@features-candidat/projet-parcours/component/parcours-creation/steps/step-information-structure/step-information-structure.module';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { CustomDateAdapter, SharedUiModule } from '@shared-ui';
import { ProjetConsortiumContactComponent } from './projet-consortium-contact.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SharedUiModule,
    MatSlideToggleModule,
    MaterialModule,
    MatSelectCountryModule.forRoot('fr'),
    StepInformationStructureModule,
  ],
  declarations: [ProjetConsortiumContactComponent],
  exports: [ProjetConsortiumContactComponent],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class ProjetConsortiumContactModule {}
