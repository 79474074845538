import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumTypeDocument, UploadDocModalDocumentModel } from '@shared-ui';

@Component({
  selector: 'pxl-modal',
  templateUrl: './select-document-modal.component.html',
  styleUrls: ['./select-document-modal.component.scss'],
})
export class SelectDocumentModalComponent {
  private static readonly VIDEO_EXTENSIONS = '.mp4, .wmv, .mov, .flv, .avi, .3gp';
  private static readonly DOC_EXTENSIONS = '.pdf, .csv, .xls, .xlsx, .doc, .docx, .ppt, .pptx,';
  private static readonly CONNAISSANCE_CLIENT_EXTENSIONS = '.pdf, .gif, .png, .jpg, .jpeg, .bmp, .tif, .tiff, .msg';

  @Output() responseModal: EventEmitter<any> = new EventEmitter();
  chosenType: UploadDocModalDocumentModel | undefined = null;
  accept: string | undefined;

  constructor(public dialogRef: MatDialogRef<SelectDocumentModalComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) {}

  onSelected() {
    if (this.chosenType?.connaissanceClient) {
      this.accept = SelectDocumentModalComponent.CONNAISSANCE_CLIENT_EXTENSIONS;
    } else if (this.chosenType?.mediaTypes?.includes(EnumTypeDocument.MEDIA_VIDEO)) {
      this.accept = SelectDocumentModalComponent.VIDEO_EXTENSIONS;
    } else {
      this.accept = SelectDocumentModalComponent.DOC_EXTENSIONS;
    }
  }

  onUpload($event: any): void {
    const result: any[] = [];
    result.push(this.chosenType?.nom);
    result.push($event.target.files);
    result.push(this.chosenType);
    this.dialogRef.close(result);
  }
}
