export const PDF = { extension: '.pdf', type: 'application/pdf'};

export const XLS = { extension:'.xls', type: 'application/vnd.ms-excel'};

export const XLSX = { extension: '.xlsx', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'};

export const DOC = { extension: '.doc', type: 'application/msword'};

export const DOCX = { extension: '.docx', type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'};

export const PPT = { extension: '.ppt', type: 'application/vnd.ms-powerpoint'};

export const PPTX = { extension: '.pptx', type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'};

export const JPG = { extension: '.jpg', type: 'image/jpeg'};

export const CSV = { extension: '.csv', type: 'text/csv'};

export const MSG = { extension: '.msg', type: 'application/vnd.ms-outlook'};

export const JPEG = { extension: '.jpeg', type: 'image/jpeg'};

export const PNG = { extension: '.png', type: 'image/png'};

export const BMP = { extension: '.bmp', type: 'image/bmp'};

export const TIFF = { extension: '.tiff', type: 'image/tiff'};

export const TIF = { extension: '.tif', type: 'image/tiff'};
