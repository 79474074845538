import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DisplayColumn } from '@shared-ui';

interface Line {
  label: string;
  data: { [key: string]: TableCelldFpE };
  commentaire?: string;
  effectif: string;
  style?: string;
  isHeader?: boolean;
}
@Component({
  selector: 'lib-table-donnees-financieres-et-pe',
  templateUrl: './table-donnees-financieres-et-pe.component.html',
  styleUrls: ['./table-donnees-financieres-et-pe.component.scss'],
})
export class TableDonneesFinancieresEtPeComponent {
  nbUnitesVendues = "Nombre d'unités vendues";

  @Input() set dataArray(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.data = data;
  }

  @Input() set columns(cols: DisplayColumn[]) {
    this.cols = cols;
    this.displayedColumns = cols.map(c => c.def);
    this.displayedColumnslength = cols.length;
  }

  @Input() previsionEconomiqueStatut: 'VALIDE' | 'NON_VALIDE';

  @Input() set responsive(value: boolean) {
    this.tableReponsive = value;
  }

  @Input() set isStructureUpdatableByUser(value: boolean) {
    this.structureUpdatableByUser = value;
  }

  @Input() set title(value: string) {
    this.arrayTitle = value;
  }

  @Input() set btnAction(value: boolean) {
    this.actionButton = value;
  }

  @Input() effectif?: boolean;

  @Output() dataChange: EventEmitter<any> = new EventEmitter();

  @Output() deleteArray: EventEmitter<string> = new EventEmitter();
  @Output() updateName: EventEmitter<string> = new EventEmitter();
  @Output() openCommentaireArray: EventEmitter<any> = new EventEmitter();

  tableReponsive: boolean;
  arrayTitle: string;
  cols: DisplayColumn[];
  displayedColumns: Iterable<string>;
  displayedColumnslength: number;
  dataSource: MatTableDataSource<any>;
  structureUpdatableByUser = true;
  actionButton = false;

  constructor() {}

  onDataChange(event: Event, element: Line, col: string): void {
    const value = +(event.target as HTMLInputElement).value;
    this.dataChange.emit({ element: element, col: col, value: value });
  }

  updateEvent(name: string): void {
    this.updateName.emit(name);
  }

  deleteEvent(name: string): void {
    this.deleteArray.emit(name);
  }

  openCommentaire(element: Line, col: string): void {
    this.openCommentaireArray.emit({ element: element, col: col });
  }
}

export class TableCelldFpE {
  value: string | number;
  constructor(value: string | number | undefined) {
    if (value || value === 0) {
      this.value = value;
    }
  }
}
