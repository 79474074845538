<div class="sidenav-depenses">
  <section class="p-3">
    <section class="d-flex align-items-center">
      <h3 class="page-header__title">Espace de travail</h3>
      <button class="ds-btn ds-btn--tertiary ms-3" (click)="close()">
        <span class="ds-icon ds-icon-left ds-float--left" aria-hidden="true">close</span>
      </button>
    </section>
    <section>
      <h5 class="mt-4">
        <b>Notes</b>
      </h5>
      <form [formGroup]="noteForm" class="mt-3">
        <div formGroupName="commentaire" class="note-commentaire mt-4">
          <textarea
            #descriptionInput
            [maxlength]="MAX_LENGTH"
            class="ds-input-group__textarea"
            id="commentaire"
            name="commentaire"
            [rows]="TEXT_AREA_ROW_HEIGHT"
            type="text"
            placeholder="Veuillez laisser votre note ici"
            formControlName="data"
          >
          </textarea>
          <div class="form-input__length-hint" [class.form-input__length-hint--full]="descriptionInput.value?.length === MAX_LENGTH">
            <span>{{ descriptionInput.value?.length || 0 }}/{{ MAX_LENGTH }}</span>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center mt-4">
        <button class="ds-btn ds-btn--secondary" [disabled]="isValidForm" (click)="saveSideNav()">Enregistrer</button>
      </div>
    </section>
  </section>
</div>
