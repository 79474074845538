import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-candidat/shared.module';
import { SharedUiModule } from '@shared-ui';
import { EnqueteRadioToggleModule } from '../../../../shared/components/enquete-radio-toggle/enquete-radio-toggle.module';
import { ProjetInfoEnqueteComponent } from './projet-info-enquete.component';

@NgModule({
  declarations: [ProjetInfoEnqueteComponent],
  imports: [CommonModule, SharedModule, SharedUiModule, EnqueteRadioToggleModule],
  exports: [ProjetInfoEnqueteComponent],
})
export class ProjetInfoEnqueteModule {}
