import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import {
  ApprobationSignatureRequest,
  CCU_FILE_NAME,
  DocumentProjet,
  DocumentService,
  EnumApprobationStatut,
  EnumScanDocument,
  EnumTypeDocument,
  EnumTypeSignataire,
  ProcedureSignatureStructure,
  Projet,
  ShowToastrService,
  SignataireProcedure,
  StructureSyntheseHelperService,
  SyntheseContractualisationStructure,
  Utilisateur,
} from '@shared-ui';
import { catchError, combineLatest, EMPTY, map, Observable, tap } from 'rxjs';

@Component({
  selector: 'app-signature-contrat-approbation',
  templateUrl: './signature-contrat-approbation.component.html',
  styleUrls: ['./signature-contrat-approbation.component.scss'],
})
export class SignatureContratApprobationComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  projetId: string;
  structureId: string;
  structureSyntheses: SyntheseContractualisationStructure;
  documents!: DocumentProjet[];
  commentaire: string;
  status: string;
  documentCcus!: DocumentProjet;
  approbation: EnumApprobationStatut;

  utilisateur!: Utilisateur;
  synthesesStructures: SyntheseContractualisationStructure[] = [];
  currentProcedure!: ProcedureSignatureStructure | null;
  currentSigner!: SignataireProcedure | undefined;
  projet: Projet;

  constructor(
    private structureService: StructureService,
    private route: ActivatedRoute,
    private router: Router,
    private projetService: ProjetService,
    private utilisateurService: UserService,
    public showToastrService: ShowToastrService,
    public documentService: DocumentService,

    public helperService: StructureSyntheseHelperService
  ) {}

  ngOnInit(): void {
    this.projetId = this.route.snapshot.parent?.params.id;
    this.structureId = this.route.snapshot.params.structureId;
    this.documentCcus = this.getDocumentCcus();

    combineLatest([this.loadUtilisateur(), this.loadProjet(), this.loadSynthesesStructures()]).subscribe(() => {
      this.synthesesStructures?.forEach(synthese => {
        this.helperService.initSyntheseStructureCustomFields(synthese, this.utilisateur);
        if (synthese.id === this.structureId) {
          this.currentProcedure = this.helperService.getCurrentProcedure(synthese);
          this.currentSigner = this.helperService.getCurrentSigner(this.currentProcedure, this.utilisateur, [
            EnumTypeSignataire.SUIVEUR_EXTERNE,
          ]);
          this.approbation = this.currentSigner?.approbation?.statut;
          this.commentaire = this.currentSigner?.approbation?.commentaire;
          this.structureSyntheses = synthese;
          this.onDocumentsStructuresLoaded(this.currentProcedure?.documentsIds);
        }
      });
    });
  }

  loadUtilisateur(): Observable<Utilisateur> {
    return this.utilisateurService.getUserObservable().pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError(err => this.handleError(err)),
      tap(utilisateur => {
        this.utilisateur = utilisateur;
      })
    );
  }

  loadProjet(): Observable<Projet> {
    return this.projetService.getProjetObservable().pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError(err => this.handleError(err)),
      tap(projet => {
        this.projet = projet;
      })
    );
  }

  loadSynthesesStructures(): Observable<SyntheseContractualisationStructure[]> {
    return this.structureService.getSyntheseContractualisationStructures(this.projetId).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(response => response ?? []),
      catchError(err => this.handleError(err)),
      tap(syntheses => {
        this.synthesesStructures = syntheses;
      })
    );
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    this.showToastrService.checkCodeError(err?.error);
    return EMPTY;
  }

  private getDocumentCcus() {
    const documentCcus = new DocumentProjet();
    documentCcus.nom = CCU_FILE_NAME;
    documentCcus.scan = EnumScanDocument.SAFE;
    documentCcus.type = 'CCUs';
    return documentCcus;
  }

  onApprobationSubmitted(): void {
    const approbationSignatureRequest = new ApprobationSignatureRequest();
    approbationSignatureRequest.statut = this.status
      ? EnumApprobationStatut.DOCUMENTS_CONTRAT_APPROUVES
      : EnumApprobationStatut.DOCUMENTS_CONTRAT_CONTESTES;
    approbationSignatureRequest.commentaire = this.commentaire;

    this.structureService.approvalSignature(this.structureId, approbationSignatureRequest).subscribe({
      next: () => {
        this.router.navigate(['/projet-creation/' + this.projetId + '/projet-contractualisation/signature-contrat']);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  onDocumentsStructuresLoaded(documentIds: string[]) {
    this.documentService.getDocumentsByIds(this.projetId, documentIds, this.structureId).subscribe(response => {
      this.documents = response;
      this.onSyntheseContractualisationStructuresLoaded();
    });
  }

  onSyntheseContractualisationStructuresLoaded() {
    const contratDocuments = this.documents.filter(
      d => d.structureId === this.structureSyntheses.id && d.typeDoc === EnumTypeDocument.CONTRAT
    );
    this.structureSyntheses.contratDocument = contratDocuments.length > 0 ? contratDocuments[0] : new DocumentProjet();
    this.structureSyntheses.contratDocument.type = 'Contrat';
    this.structureSyntheses.mandatDocuments = this.documents
      .filter(d => d.structureId === this.structureSyntheses.id && d.typeDoc === EnumTypeDocument.MANDAT)
      .map(mandatDocument => {
        mandatDocument.type = 'Mandat';
        return mandatDocument;
      });
  }
}
