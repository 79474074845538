import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { SharedUiModule } from '@shared-ui';
import { SelectDocumentModalComponent } from './select-document-modal.component';

@NgModule({
  declarations: [SelectDocumentModalComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, SharedModule, SharedUiModule],
})
export class SelectDocumentModalModule {}
