import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjetInfoComponent } from './projet-info.component';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [ProjetInfoComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    SharedUiModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
})
export class ProjetInfoModule {}
