import { Inject, Injectable } from '@angular/core';
import { Environment } from '../../models/environment.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FicheDemandeAideStatut } from '../../models/fiche-demande-aide-statut.model';

@Injectable({
  providedIn: 'root',
})
export class FicheDemandeAideHttpService {
  private readonly ficheDemandeAideUrl;

  constructor(@Inject('environment') private environment: Environment, private httpClient: HttpClient) {
    this.ficheDemandeAideUrl = this.environment.apiUrl + 'fiches-demande-aide';
  }

  public fetchFichesDemandeAideStatusByProjectId(idProjet: string): Observable<FicheDemandeAideStatut[]> {
    return this.httpClient.get<FicheDemandeAideStatut[]>(this.ficheDemandeAideUrl + '/projets/' + idProjet + '/statuts');
  }
}
