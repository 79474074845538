import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { StructureService } from '@services-candidat/structure.service';
import { Structure } from '@shared-ui';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const fullStructureResolver: ResolveFn<Structure | null> = route => {
  const structureService = inject(StructureService);
  const idStructure = route.paramMap.get('structureId');

  if (idStructure) {
    return forkJoin([
      structureService.getStructureById(idStructure),
      structureService.getDonneesFinancieresByStructureId(idStructure),
      structureService.getPrevisionsEconomiquesByStructureId(idStructure),
    ]).pipe(
      map(([structureHttpResponse, donneesFinancieresHttpResponse]) => {
        const structure = structureHttpResponse.body as Structure;
        const donneesFinancieres = donneesFinancieresHttpResponse.body;

        structure.donneesFinancieres = donneesFinancieres;
        return structure;
      })
    );
  } else {
    return of(null);
  }
};
