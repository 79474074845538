import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  Budget,
  BudgetRequest,
  EnumProjetEtape,
  EnumTypePartenaire,
  Projet,
  PxlValidators,
  ShowToastrService,
  Structure,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-projet-consortium-budget',
  templateUrl: './projet-consortium-budget.component.html',
  styleUrls: ['./projet-consortium-budget.component.scss'],
})
export class ProjetConsortiumBudgetComponent extends SubscriptionDestroyerComponent implements OnInit {
  projet: Projet = new Projet();
  budgetForm: UntypedFormGroup;
  structure: Structure;
  structures: Structure[];
  utilisateur: Utilisateur;
  projetId: string;
  structureId: string;
  targetedCreation: string;
  aap: Aap = new Aap();
  raisonSocial: string;
  error: boolean;

  isStructureUpdatableByUser: boolean;

  infoAide =
    "Assiette des dépenses éligibles présentées (se référer au cahier des charges correspondant pour connaître les types de dépenses éligibles et les montants d'assiettes minimum et/ou maximum attendus)";
  infoAideDemande =
    "Montant de l'aide que vous souhaitez obtenir dans la limite des taux maximum indiqués dans le cahier des charges correspondant";
  infoAideTotal =
    'Ensemble du budget prévisionnel envisagé de votre structure, incluant les dépenses éligibles et les dépenses que vous réaliserez pour la bonne conduite de votre projet';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private projetService: ProjetService,
    private showToastrService: ShowToastrService,
    private aapService: AapService,
    private userService: UserService,
    public sharedFunction: SharedFunction,
    private structureService: StructureService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForms();
    this.getProjet();

    this.budgetForm
      .get('besoin')
      .valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe(value => {
        if (!value) {
          this.budgetForm.get('montant').clearValidators();
          this.budgetForm.get('montant').setValue('0');
          this.budgetForm.get('montantAideDemande').clearValidators();
          this.budgetForm.get('montantAideDemande').setValue('0');
          this.budgetForm.updateValueAndValidity();
        } else {
          this.budgetForm.get('montant').setValidators([Validators.required]);
          this.budgetForm.get('montant').reset();
          this.budgetForm.get('montantAideDemande').setValidators([Validators.required]);
          this.budgetForm.get('montantAideDemande').reset();
          this.budgetForm.updateValueAndValidity();
        }
      });
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.budgetForm.dirty;
  }

  get isDemandeFinancement(): boolean {
    return this.budgetForm.get('besoin').value;
  }

  createForms(): void {
    this.budgetForm = this.formBuilder.group({
      montant: [null],
      besoin: [true, Validators.required],
      montantAideDemande: [null],
      budgetTotal: [null],
    });
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    this.projetId = this.route.snapshot.parent.params.id;
    this.projetService
      .getProjetObservable()
      .pipe(
        concatMap(responseProjet => {
          if (responseProjet) {
            this.projet = responseProjet;
            this.userService
              .getUserObservable()
              .pipe(this.takeUntilDestroyed())
              .subscribe(response => {
                if (response) {
                  this.utilisateur = response;
                }
              });

            return this.aapService.loadAapSubject();
          }
        })
      )
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: responseAap => {
          if (responseAap) {
            this.aap = responseAap.body;
            this.getStructure();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  getStructure(): void {
    this.structureId = this.route.snapshot.params.structureId;
    if (this.structureId) {
      this.structureService
        .getStructuresListObservable()
        .pipe(this.takeUntilDestroyed())
        .subscribe(response => {
          this.structures = response;
          this.structure = response.find(structure => structure.id === this.structureId);
          this.isStructureUpdatableByUser = this.sharedFunction.isStructureUpdatableByUser(
            this.aap,
            this.projet,
            this.structure,
            this.utilisateur
          );
          this.raisonSocial = this.structure.raisonSocial;
          this.setBudgetForm();
        });
    }
  }

  setBudgetForm(): void {
    let budget: Budget;
    if (this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime) {
      budget = this.structure.budgetPreDepot;
    } else if (this.sharedFunction.getProjectEtapeName(this.projet) !== EnumProjetEtape.PRE_DEPOT) {
      budget = this.structure.budgetDepot;
    }

    if (budget?.besoin === null) {
      budget.besoin = true;
    }

    if (budget?.besoin) {
      this.budgetForm.get('montant').setValidators([Validators.required, PxlValidators.isNumberValidator()]);
      this.budgetForm.get('montantAideDemande').setValidators([Validators.required, PxlValidators.isNumberValidator()]);
      this.budgetForm.get('budgetTotal').setValidators([Validators.required, PxlValidators.isNumberValidator()]);
      this.budgetForm.get('montantAideDemande').setValue(budget?.montantAideDemande ?? null);
    }
    this.budgetForm.get('montant').setValue(budget?.montant ?? null);
    this.budgetForm.get('besoin').setValue(budget?.besoin ?? true);
    if (budget?.budgetTotal == null) {
      this.budgetForm.get('budgetTotal').setValue(null);
    } else {
      this.budgetForm.get('budgetTotal').setValue(budget?.budgetTotal);
    }
  }

  private setValueBudget() {
    if (this.budgetForm.value.montant) {
      this.budgetForm.patchValue({
        montant: this.budgetForm.value.montant.replace(/\s/g, '') ?? null,
      });
    }
    if (this.budgetForm.value.montantAideDemande) {
      this.budgetForm.patchValue({
        montantAideDemande: this.budgetForm.value.montantAideDemande.replace(/\s/g, '') ?? null,
      });
    }
    if (this.budgetForm.value.budgetTotal) {
      this.budgetForm.patchValue({
        budgetTotal: this.budgetForm.value.budgetTotal.replace(/\s/g, '') ?? null,
      });
    }
  }

  onFocus(): void {
    this.setValueBudget();
  }

  onBlur(): void {
    this.setValueBudget();
  }

  /*
   * Modifie le budget d'un projet
   * */
  saveBudget(): void {
    if (!this.budgetForm.valid) {
      this.budgetForm.markAllAsTouched();
      return;
    }
    const isPreDepot = this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime;
    const budgetRequest = new BudgetRequest();
    budgetRequest.budget = new Budget();
    budgetRequest.isPredepot = isPreDepot;

    budgetRequest.budget.montant = this.budgetForm.value.montant ?? '0';
    budgetRequest.budget.besoin = this.budgetForm.value.besoin;
    budgetRequest.budget.montantAideDemande = this.budgetForm.value.montantAideDemande ?? '0';
    budgetRequest.budget.budgetTotal = this.budgetForm.value.budgetTotal ?? '';

    this.structureService
      .updateBudget(this.structure?.id, budgetRequest)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<Structure>) => {
          if (response) {
            this.budgetForm.reset();
            this.budgetForm.markAsPristine();
            this.structureService.setUniqueStructureListObservable(response.body, this.structures);
            this.showToastrService.success('La structure ' + this.structure.raisonSocial + ' a bien été modifiée');
            this.onGoToConsortiumInfo();
          }
        },
        error: () => {
          this.showToastrService.error('Le champ doit être un nombre entier positif');
        },
      });
  }

  /*
   * Permet de retourner à la page d'information consortium
   * */
  onGoToConsortiumInfo(): void {
    this.router.navigate(['projet-creation', this.projetId, 'projet-consortium-info', this.structureId]);
  }

  protected readonly EnumTypePartenaire = EnumTypePartenaire;
}
