import { HttpErrorResponse, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { DemandeDeDocumentComplementaireService } from '@services-candidat/demandeDeDocumentComplementaire.service';
import { DepensesService } from '@services-candidat/depenses.service';
import { PoleService } from '@services-candidat/pole.service';
import { ProjetService } from '@services-candidat/projet.service';
import { UploadDocumentService } from '@services-candidat/upload-document.service';
import { UserService } from '@services-candidat/user.service';
import { ConfirmModalComponent } from '@shared-candidat/components/modals/confirm-modal/confirm-modal.component';
import { URL_PUBLIC_DOC } from '@shared-candidat/utils/constants';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  DemandeComplements,
  DocumentAapModel,
  DocumentConfigModel,
  DocumentFileManagerService,
  DocumentHelper,
  DocumentProjet,
  DocumentService,
  Domaine,
  EnumDocumentComplementaireType,
  EnumDocumentProjetType,
  EnumProjetEtape,
  EnumRoleStructure,
  EnumScanDocument,
  EnumScope,
  EnumTypeDocument,
  EnumTypePartenaire,
  EnumValidation,
  FicheComHelperService,
  FILE_SIZE,
  GrilleImpactHelperService,
  GrilleImpactHttpService,
  LETTRE_LABELISATION,
  Projet,
  ProjetValidationFunction,
  Secteur,
  ShowToastrService,
  StatutGrilleImpacts,
  Structure,
  SubscriptionDestroyerComponent,
  Thematique,
  UploadDocModalDocumentModel,
  UploadDocModalResultModel,
  Utilisateur,
  VIDEO_SIZE,
} from '@shared-ui';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, concatMap, finalize, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-projet-info',
  templateUrl: './projet-info.component.html',
  styleUrls: ['./projet-info.component.scss'],
})
export class ProjetInfoComponent extends SubscriptionDestroyerComponent implements OnInit, OnDestroy {
  readonly DEFAULT_FILE_TYPES = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'application/vnd.ms-excel',
    'video/mp4',
    'video/x-ms-wmv',
    'video/quicktime',
    'video/avi',
    'video/3gpp',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
  ];

  readonly CONNAISSANCE_CLIENT_FILE_TYPES = [
    'application/pdf', // .pdf
    'image/gif', // .gif
    'image/png', // .png
    'image/jpeg', // .jpg, .jpeg
    'image/bmp', // .bmp
    'image/tiff', // .tif, .tiff
    'application/vnd.ms-outlook', // .msg
  ];

  readonly FILE_SIZE_LIMIT = FILE_SIZE;
  readonly VALIDE = (EnumValidation as any)[EnumValidation.VALIDE.toString()];

  dossier: string;
  subscriptions: Subscription[] = [];
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  inputType = 'file';
  fileTooBig = false;
  videoTooBig = false;
  fileNotSupported = false;
  fileComplTooBig = false;
  fileComplNotSupported = false;
  connaissanceClientFileNotSupported = false;
  connaissanceClientFileComplNotSupported = false;
  showDiv = false;
  allowDownload = false;
  documentConfigs: DocumentConfigModel[] = [];
  documentConfigsForComplement: DocumentConfigModel[];
  allDocuments: DocumentProjet[] = [];
  listDocumentsProjet: DocumentProjet[] = [];
  demandesComplements: DemandeComplements[] = [];
  demandesComplementsEncours: DemandeComplements[] = [];
  documentsComplementaires: DocumentProjet[] = [];
  etapeProjet: EnumProjetEtape;
  utilisateur: Utilisateur;
  projetId: string;

  structureList: Structure[];
  declaredBudgetSum = 0;

  poles = [];
  listDocumentPoles: any[] = [];

  isKpiProjetNeeded = false;
  isGrilleImpactsValid: boolean;

  isExpensesValid = false;
  isFicheComValid = false;

  secteurs: Array<Secteur> = [];
  secteurSelected = false;
  domaines: Array<Domaine> = [];
  thematique: Thematique;

  isUploading = false;

  // Permissions
  isProjectUpdatableByUser: boolean;
  isGrilleImpactUpdatable = false;

  EnumProjetEtape = EnumProjetEtape;

  nameFileNotSupported: string[] = [];
  viewFicheComm = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public projetService: ProjetService,
    private depensesService: DepensesService,
    private poleService: PoleService,
    public uploadDocumentService: UploadDocumentService,
    public documentFileManagerService: DocumentFileManagerService,
    private showToastrService: ShowToastrService,
    private aapService: AapService,
    public userService: UserService,
    public sharedFunction: SharedFunction,
    private dialog: MatDialog,
    private grilleImpactHttpService: GrilleImpactHttpService,
    private demandeComplementService: DemandeDeDocumentComplementaireService,
    public projetValidationFunction: ProjetValidationFunction,
    public documentService: DocumentService
  ) {
    super();
  }

  ngOnInit(): void {
    this.projetId = this.route.snapshot.parent.params.id;
    this.getProjetIfExists();
    this.getProjetDocuments();
    this.getExpensesStatus();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.isUploading;
  }

  /*
   * Cette méthode récupère le projet si l'id est renseigné.
   * */
  getProjetIfExists(): void {
    if (!this.projetId) {
      return;
    }

    this.subscriptions.push(
      this.projetService
        .getProjetById(this.projetId)
        .pipe(
          concatMap(responseProjet => {
            this.projet = responseProjet?.body;
            this.getProjetStructures(this.projet);

            this.subscriptions.push(
              this.userService.getUserObservable().subscribe(response => {
                if (response) {
                  this.utilisateur = response;
                }
              })
            );

            this.isFicheComValid = FicheComHelperService.isFicheComComplete(this.projet);
            this.showDiv = this.checkProjetInfo();
            this.secteurs = this.projet.secteurs;
            this.domaines = this.projet.domaines;
            this.thematique = this.projet.thematique;
            return this.aapService.loadAapSubject();
          })
        )
        .subscribe({
          next: responseAap => {
            this.aap = responseAap?.body;
            this.dossier = this.aap.lienDocumentation;

            this.isKpiProjetNeeded = this.aap.kpis?.filter(kpi => kpi.scope === 'PROJET').length > 0;

            if (this.isKpiProjetNeeded) {
              this.getProjetKpiStatus();
            }

            this.etapeProjet = this.sharedFunction.getProjectEtapeName(this.projet);
            this.documentConfigs = this.getDocumentConfigs();
            this.isProjectUpdatableByUser = this.sharedFunction.isProjectUpdatableByUser(this.aap, this.projet, this.utilisateur);
            this.isGrilleImpactUpdatable = !this.sharedFunction.isUserInStructureCandidatContacts(this.projet, this.utilisateur);
            if (!this.isProjectUpdatableByUser) {
              this.inputType = 'none';
            }
            this.updateFicheCommVisibilty();
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        })
    );
  }

  private getDocumentConfigs() {
    return this.aap.documentAaps
      ?.filter(
        document =>
          document.etapes.includes(this.etapeProjet) &&
          document.typePartenaires?.includes(this.projet.partenaireType) &&
          document.scope === EnumScope.PROJET
      )
      .sort((a, b) => (a.nomDocument > b.nomDocument ? 1 : -1));
  }

  getProjetStructures(projet: Projet): void {
    this.structureList = projet.structures.filter(structure => structure.role !== EnumRoleStructure.MANDATAIRE && !structure.closed);
    this.structureList.sort((a, b) => (a.raisonSocial.toLocaleLowerCase() > b.raisonSocial.toLocaleLowerCase() ? 1 : -1));
    this.declaredBudgetSum = 0;
    this.structureList.forEach(structure => {
      if (this.sharedFunction.getProjectEtapeName(this.projet) === EnumProjetEtape.PRE_DEPOT && !this.aap.budgetEstime) {
        this.declaredBudgetSum += structure.budgetPreDepot?.montant ? Number(structure.budgetPreDepot.montant) : 0;
      } else if (this.sharedFunction.getProjectEtapeName(this.projet) !== EnumProjetEtape.PRE_DEPOT) {
        this.declaredBudgetSum += structure.budgetDepot?.montant ? Number(structure.budgetDepot.montant) : 0;
      }
    });
  }

  getProjetDocuments(): void {
    const typesDocuments = [
      EnumTypeDocument.PROJET,
      EnumTypeDocument.MEDIA_VIDEO,
      EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE,
      EnumTypeDocument.POLE,
      EnumTypeDocument.DOCUMENT_NON_FOURNI,
    ];
    const fork = forkJoin({
      poles: this.poleService.getProjetPoles(this.projetId),
      documents: this.documentService.getDocuments(this.projetId, typesDocuments),
      demandesComplements: this.demandeComplementService.getDemandesComplements(this.projetId),
    });

    fork.pipe(catchError(error => of(error)));

    fork.pipe(this.takeUntilDestroyed()).subscribe({
      next: result => {
        this.poles = result.poles.body;
        this.demandesComplements = result.demandesComplements?.body;
        this.demandesComplementsEncours = this.demandesComplements?.filter((d: DemandeComplements) =>
          ['ENVOYEE', 'COMPLETEE'].includes(d.statut)
        );
        this.allDocuments = result.documents;
        this.handleDocuments();
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  handleDocuments() {
    this.listDocumentsProjet = this.allDocuments.filter(
      doc => doc.type !== LETTRE_LABELISATION && doc.typeDoc !== EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE
    );

    // handle poles documents
    this.listDocumentPoles = this.allDocuments.filter(
      (doc: DocumentProjet) => doc.type === LETTRE_LABELISATION && this.poles.find(p => p.id === doc.poleId)
    );
    this.poles.forEach(pole => (pole.hasDoc = this.allDocuments.some(i => i.poleId === pole.id)));

    // handle documents complementaires documents
    this.documentsComplementaires = this.allDocuments.filter(doc => doc.typeDoc === EnumTypeDocument.DOCUMENT_COMPLEMENTAIRE);
    this.documentsComplementaires.forEach(
      doc => (doc.type = EnumDocumentComplementaireType.toString(doc.type as EnumDocumentComplementaireType))
    );
    this.documentConfigsForComplement = this.getDocumentConfigsForComplements();
  }

  private getDocumentConfigsForComplements(): DocumentConfigModel[] {
    return this.demandesComplementsEncours
      .filter(demande => demande.documents && demande.documents.length > 0)
      .flatMap(demande =>
        demande.documents.map(document => ({
          nomDocument: document.type,
          etapes: [],
          typeStructures: [],
          scope: EnumScope.PROJET,
          accesDocument: '',
          connaissanceClient: false,
          obligatoire: document.obligatoire,
          demandeComplementId: demande.id,
          demandeComplementDocumentId: document.id,
          description: document.description,
        }))
      );
  }

  getProjetKpiStatus(): void {
    this.subscriptions.push(
      this.grilleImpactHttpService
        .getProjectAndStructuresKpisStatus(this.projetId)
        .pipe(map(GrilleImpactHelperService.getGIProjetStatut))
        .subscribe({
          next: this.setGrilleImpactsValid.bind(this),
          error: (err: HttpErrorResponse) => {
            if (err.status === HttpStatusCode.NotFound) return;
            this.showToastrService.checkCodeError(err?.error);
          },
        })
    );
  }

  private setGrilleImpactsValid(statut: StatutGrilleImpacts) {
    this.isGrilleImpactsValid = GrilleImpactHelperService.isGrilleImpactsValid(statut);
  }

  getExpensesStatus(): void {
    this.depensesService
      .getProjetExpensesStatus(this.projetId)
      .pipe(take(1))
      .subscribe({
        next: (resp: HttpResponse<EnumValidation>) => {
          this.isExpensesValid = resp.body === this.VALIDE;
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.NotFound) {
            return;
          }
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  /*
   * Vérifie si les informations du projet sont remplies
   * */
  checkProjetInfo(): boolean {
    return this.projet.nom != null && this.projet.description != null;
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isValidDocument(document: DocumentProjet): boolean {
    return document?.scan === EnumScanDocument.SAFE;
  }

  isScanedDocument(document: DocumentProjet): boolean {
    return document?.scan === EnumScanDocument.SAFE || document?.scan === EnumScanDocument.UNSAFE;
  }

  calculatePurcent(montant: string): number {
    return Math.round((parseInt(montant.replace(/\s/g, ''), 10) * 100) / this.declaredBudgetSum);
  }

  getPoleName(id: string): string {
    const pole = this.poles.find(i => i.id === id);
    return pole ? pole.nomCommercial : '';
  }

  onSecteurSelected(): void {
    this.secteurSelected = !this.secteurSelected;
  }

  /*
   * Permet d'aller au formulaire vide
   * */
  onGoToProjetInfoGene(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-general'], { relativeTo: this.route });
  }

  onGoToBudgetInfo(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-budget'], { relativeTo: this.route });
  }

  onGoToBudgetChefDeFile(): void {
    const chefDeFile = this.structureList.filter(structure => structure.role === EnumRoleStructure.CHEF_DE_FILE)[0];
    if (chefDeFile) {
      this.onGoToBudgetStructure(chefDeFile.id);
    }
  }

  onGoToBudgetStructure(structureId): void {
    if (this.isProjectUpdatableByUser) {
      this.router.navigate(['../projet-consortium-info/' + structureId + '/projet-consortium-budget'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../projet-consortium-info/' + structureId], { relativeTo: this.route });
    }
  }

  onGoToPolesInfo(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-poles'], { relativeTo: this.route });
  }

  onGoToSecteursInfo(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-secteurs'], { relativeTo: this.route });
  }

  onGoToDomainesInfo(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-domaines'], { relativeTo: this.route });
  }

  onGoToThematiqueInfo(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-thematique'], { relativeTo: this.route });
  }

  onGoToEnquete(): void {
    this.router.navigate(['../projet-info-enquete'], { relativeTo: this.route });
  }

  onGoToGrilleImpacts(): void {
    this.router.navigate(['../projet-info-grille-impacts'], { relativeTo: this.route });
  }

  onGoToExpensesInfo(): void {
    if (!this.isProjectUpdatableByUser) {
      return;
    }
    this.router.navigate(['../projet-info-depenses'], { relativeTo: this.route });
  }

  onGoToFicheComInfo(): void {
    this.router.navigate(['../projet-info-fiche-com'], { relativeTo: this.route });
  }

  onUploadDocumentDnd(result: UploadDocModalResultModel) {
    this.allowDownload = true;
    this.onUpload(result.files, result.document, result.commentaire);
  }

  updateDocumentsAfterDelete(documentProjet: DocumentProjet) {
    this.allDocuments = this.allDocuments.filter(doc => doc.id !== documentProjet.id);
    this.handleDocuments();
  }

  onUploadPoleDocument($event: any, index: number): void {
    const result: any[] = [];
    result.push($event.target.files);

    this.poles[index].fileTooBig = false;
    this.poles[index].fileNotSupported = false;
    this.poles[index].fileToUpload = result[0].item(0);

    if (this.validatePoleDocumentFile(index)) {
      this.uploadDocumentProjet(this.buildPoleDocument(index), this.poles[index].fileToUpload);
    }
  }

  private validatePoleDocumentFile(index: number): boolean {
    if (this.listDocumentPoles.find(i => i.poleId === this.poles[index].id)) {
      this.showToastrService.checkCodeError({ code: 'document.present' }, this.poles[index].nomCommercial);
      this.poles[index].hasDoc = true;
      return false;
    }

    if (!this.poles[index].fileToUpload || this.poles[index].fileToUpload.size === 0) {
      this.poles[index].fileEmpty = true;
      return false;
    }

    if (this.poles[index].fileToUpload.size > FILE_SIZE * 1048576) {
      this.poles[index].fileTooBig = true;
      return false;
    }

    if (!['application/pdf'].includes(this.poles[index].fileToUpload.type)) {
      this.poles[index].fileNotSupported = true;
      return false;
    }
    return true;
  }

  private buildPoleDocument(index: number): DocumentProjet {
    const documentPole = new DocumentProjet();
    documentPole.nom = this.poles[index].fileToUpload?.name || '';
    documentPole.dateModification = this.projet.dateModification;
    documentPole.projetId = this.projet.id;
    documentPole.poleId = this.poles[index].id;
    documentPole.type = LETTRE_LABELISATION;
    documentPole.scope = EnumScope.PROJET;
    documentPole.typeDoc = EnumTypeDocument.POLE;
    return documentPole;
  }

  isFileSizeValid(file: File, videoDocEnable: boolean): boolean {
    if (videoDocEnable) {
      const isFileSizeValid = !(file.size && file.size > VIDEO_SIZE * 1048576);
      if (!isFileSizeValid) {
        this.nameFileNotSupported.push(file.name);
        this.nameFileNotSupported = this.nameFileNotSupported.filter((item, index) => this.nameFileNotSupported.indexOf(item) === index);
      }
      return isFileSizeValid;
    }

    const isFileSizeValid = !(file.size && file.size > FILE_SIZE * 1048576);
    if (!isFileSizeValid) {
      this.nameFileNotSupported.push(file.name);
      this.nameFileNotSupported = this.nameFileNotSupported.filter((item, index) => this.nameFileNotSupported.indexOf(item) === index);
    }
    return isFileSizeValid;
  }

  isFileTypeValid(file: File, supportedTypesExtensions: string[]): boolean {
    if (!supportedTypesExtensions.includes(file.type)) {
      this.nameFileNotSupported.push(file.name);
      this.nameFileNotSupported = this.nameFileNotSupported.filter((item, index) => this.nameFileNotSupported.indexOf(item) === index);
    }
    return supportedTypesExtensions.includes(file.type);
  }

  setFileInvalidityFlags(
    document: UploadDocModalDocumentModel,
    isFileSizeValid: boolean,
    isFileTypeValid: boolean,
    videoDocEnable: boolean
  ): void {
    const isConnaissanceClient = !!document.connaissanceClient;
    const isDocumentComplementaire = !!document.demandeComplementId;

    this.fileTooBig = !videoDocEnable && !isDocumentComplementaire && !isFileSizeValid;
    this.fileComplTooBig = !videoDocEnable && isDocumentComplementaire && !isFileSizeValid;
    this.videoTooBig = videoDocEnable && !isFileSizeValid;

    this.fileNotSupported = !isDocumentComplementaire && !isConnaissanceClient && !isFileTypeValid;
    this.fileComplNotSupported = isDocumentComplementaire && !isConnaissanceClient && !isFileTypeValid;

    this.connaissanceClientFileNotSupported = !isDocumentComplementaire && isConnaissanceClient && !isFileTypeValid;
    this.connaissanceClientFileComplNotSupported = isDocumentComplementaire && isConnaissanceClient && !isFileTypeValid;
  }

  resetFileInvalidityFlags(): void {
    this.fileTooBig = false;
    this.fileComplTooBig = false;
    this.videoTooBig = false;
    this.fileNotSupported = false;
    this.fileComplNotSupported = false;
    this.connaissanceClientFileNotSupported = false;
    this.connaissanceClientFileComplNotSupported = false;
  }

  onUpload(files: FileList, document?: UploadDocModalDocumentModel, commentaire?: string): void {
    if (commentaire) {
      const documentProjet = new DocumentProjet();
      documentProjet.type = document?.nom;
      documentProjet.typeDoc = EnumTypeDocument.DOCUMENT_NON_FOURNI;
      documentProjet.createur = this.utilisateur?.matricule;
      documentProjet.scope = EnumScope.PROJET;
      documentProjet.projetId = this.projet.id;
      documentProjet.commentaire = commentaire;
      this.uploadDocumentProjet(documentProjet, null);
      return;
    }

    this.resetFileInvalidityFlags();
    for (const item of Array.from(files)) {
      if (this.allowDownload) {
        const isConnaissanceClient = !!document.connaissanceClient;
        const fileToUpload = item as File;
        const fileTypes = isConnaissanceClient ? this.CONNAISSANCE_CLIENT_FILE_TYPES : this.DEFAULT_FILE_TYPES;
        const videoDocEnable = DocumentHelper.isVideoMediaTypes(document?.mediaTypes);
        const isFileSizeValid = this.isFileSizeValid(fileToUpload, videoDocEnable);
        const isFileTypeValid = this.isFileTypeValid(fileToUpload, fileTypes);

        if (fileToUpload && isFileSizeValid && isFileTypeValid) {
          this.uploadDocument(fileToUpload, document);
        } else {
          this.setFileInvalidityFlags(document, isFileSizeValid, isFileTypeValid, videoDocEnable);
        }
      }
    }
    this.allowDownload = false;
  }

  uploadDocument(fileToUpload: File, document?: UploadDocModalDocumentModel) {
    const documentProjet: DocumentProjet = DocumentHelper.buildDocumentProjetWithEtape(
      fileToUpload.name,
      this.projet,
      this.sharedFunction.getProjectEtapeName(this.projet),
      document
    );
    this.uploadDocumentProjet(documentProjet, fileToUpload);
  }

  private uploadDocumentProjet(documentProjet: DocumentProjet, file: File | undefined): void {
    this.isUploading = true;
    this.documentService
      .uploadDocument(documentProjet, file)
      .pipe(
        this.takeUntilDestroyed(),
        finalize(() => (this.isUploading = false))
      )
      .subscribe({
        next: (documentCreated: DocumentProjet) => {
          this.allDocuments = this.allDocuments.filter(
            doc => !(doc.type === documentCreated.type && doc.typeDoc === EnumTypeDocument.DOCUMENT_NON_FOURNI)
          );
          this.allDocuments.push(documentCreated);
          this.handleDocuments();
          if (!file) {
            this.showToastrService.success('Commentaire sauvegardé avec succès');
          } else {
            this.showToastrService.success('Le document a bien été ajouté');
          }
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  /*
   * Affiche le nom du créateur du Document
   * */
  getNomCreateurDocument(document: DocumentProjet): string {
    return DocumentHelper.getNomCreateurDocument(document);
  }

  /*
   * Download a document
   * */
  downloadDocument(document: DocumentProjet): void {
    this.documentFileManagerService.downloadDocument(document).pipe(this.takeUntilDestroyed()).subscribe();
  }

  /*
   * Affiche la modale pour supprimer un document
   * */
  onDeleteDocument(document: DocumentProjet): void {
    if (document.etape !== this.sharedFunction.getProjectEtapeName(this.projet).toString()) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        description: `<p>Êtes-vous sûr de vouloir supprimer ce fichier.</p>
                          <p>Cette action est irréversible. </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
        icon: true,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(this.takeUntilDestroyed())
      .subscribe(confirmed => {
        if (confirmed) {
          this.deleteDocument(document);
        }
      });
  }

  deleteDocument(document: DocumentProjet): void {
    this.documentService
      .delete(document)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.allDocuments = this.allDocuments.filter(doc => doc.id !== document.id);
          this.handleDocuments();
          this.showToastrService.success('Le fichier a bien été supprimé');
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
          // If the suppression of the document fails only for S3
          this.getProjetDocuments();
        },
      });
  }

  downloadRGPD(): void {
    window.open(URL_PUBLIC_DOC + this.aap.programme.fichierRgpd);
  }

  getDocumentTypeLabel(type: string): string {
    return EnumDocumentProjetType[type];
  }

  /**
   * Retourne un Boolean pour afficher le message Lecture seule en haut de la page
   */
  showReadOnlyMessage(): boolean {
    return this.aap?.id != null && !this.isProjectUpdatableByUser;
  }

  /**
   * Retourne un Boolean pour afficher le lien de dossier de candidature
   */
  showDossierCandidatureLink(): boolean {
    return this.etapeProjet === EnumProjetEtape.PRE_DEPOT && this.aap.lienDocumentation?.length > 0;
  }

  toUploadDocModalDocumentModel(documents: DocumentAapModel[]): UploadDocModalDocumentModel[] {
    return documents.map((document: DocumentAapModel) => ({
      nom: document.nomDocument,
      mediaTypes: document.mediaTypes,
      connaissanceClient: document.connaissanceClient,
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }

  updateFicheCommVisibilty() {
    if (this.sharedFunction.getProjectEtapeName(this.projet) !== EnumProjetEtape.PRE_DEPOT && this.aap.ficheComPresent) {
      this.viewFicheComm = true;
    }
  }

  isBudgetSumNotValid(): boolean {
    return (
      !this.structureList || (!this.declaredBudgetSum && this.structureList.some((structure: Structure) => structure.budgetDepot?.besoin))
    );
  }

  protected readonly EnumScope = EnumScope;
  protected readonly EnumTypePartenaire = EnumTypePartenaire;
  protected readonly enumValidation = EnumValidation;
}
