import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InformationBancaire } from '../../models/information-bancaire.model';
import { Environment, SITE_ADMIN } from '../../models/environment.model';
import { ValidationManuelleRequestInterface } from '../../interfaces/information-bancaire.interface';
import { EnumTypePaiement } from '../../enums/enum.type-paiement';

export enum EnumInformationBancaireOrigin {
  PXL = 'PXL',
  RET = 'RET',
}

@Injectable({
  providedIn: 'root',
})
export class InformationsBancairesHttpService {
  informationBancaireUrl: string;
  documentUrl: string;

  constructor(@Inject('environment') public environment: Environment, private httpClient: HttpClient) {
    this.informationBancaireUrl =
      (this.environment.site === SITE_ADMIN ? this.environment.apiAdminUrl : this.environment.apiUrl) + 'informations-bancaires';
    this.documentUrl = this.environment.apiUrl + 'documents';
  }

  getInformationsBancaires(structureId: string, from = EnumInformationBancaireOrigin.PXL): Observable<HttpResponse<InformationBancaire[]>> {
    return this.httpClient.get<InformationBancaire[]>(this.informationBancaireUrl + `?structureId=${structureId}&origine-iban=${from}`, {
      observe: 'response',
    });
  }

  createOrUpdateInformationBancaire(informationBancaire: InformationBancaire): Observable<HttpResponse<InformationBancaire>> {
    if (!informationBancaire.id) {
      return this.createInformationBancaire(informationBancaire);
    } else {
      return this.updateInformationBancaire(informationBancaire.id, informationBancaire);
    }
  }

  updateInformationBancaire(id: string, informationBancaire: InformationBancaire): Observable<HttpResponse<InformationBancaire>> {
    return this.httpClient.put<InformationBancaire>(this.informationBancaireUrl + `/${id}`, JSON.stringify(informationBancaire), {
      observe: 'response',
    });
  }

  createInformationBancaire(informationBancaire: InformationBancaire): Observable<HttpResponse<InformationBancaire>> {
    return this.httpClient.post<InformationBancaire>(this.informationBancaireUrl, JSON.stringify(informationBancaire), {
      observe: 'response',
    });
  }

  selectInformationBancaire(informationBancaire: InformationBancaire): Observable<HttpResponse<void>> {
    const id = informationBancaire.id ?? informationBancaire.ibanRetId;
    return this.httpClient.patch<void>(this.informationBancaireUrl + `/${id}/select?structureId=${informationBancaire.structureId}`, null, {
      observe: 'response',
    });
  }

  validateInformationBancaireManually(
    id: string,
    structureId: string,
    validation: ValidationManuelleRequestInterface
  ): Observable<HttpResponse<InformationBancaire>> {
    return this.httpClient.patch<InformationBancaire>(
      this.informationBancaireUrl + `/${id}/validation-manuelle?structureId=${structureId}`,
      JSON.stringify(validation),
      {
        observe: 'response',
      }
    );
  }

  validateInformationBancaireAutomatically(id: string, typesPaiements: EnumTypePaiement[]): Observable<HttpResponse<InformationBancaire>> {
    return this.httpClient.post<InformationBancaire>(this.informationBancaireUrl + `/${id}/demande-certification`, typesPaiements, {
      observe: 'response',
    });
  }
}
