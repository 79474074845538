import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { ProjetConsortiumFicheDemandeAideComponent } from './projet-consortium-fiche-demande-aide.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxPermissionsModule } from 'ngx-permissions';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [ProjetConsortiumFicheDemandeAideComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    RouterModule,
    SharedUiModule,
    SharedModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
})
export class ProjetConsortiumFicheDemandeAideModule {}
