import { enumKeys } from "../../utils/enum-utils";

export enum EnumTypeAide {
  OBTENU = 'OBTENU',
  EN_ATTENTE = 'EN_ATTENTE',
}

export namespace EnumTypeAide {
  export function toString(typeAide: EnumTypeAide): string {
    switch (typeAide) {
      case EnumTypeAide.OBTENU:
        return 'Obtenu';
      case EnumTypeAide.EN_ATTENTE:
        return 'En attente';
    }
  }

  export function all(): EnumTypeAide[] {
    return enumKeys(EnumTypeAide) as unknown as EnumTypeAide[];
  }
}
