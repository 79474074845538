<!-- Entête de la page -->
<div class="page-header">
  <h2 id="titre_page" class="page-header__title">{{ syntheseStructure?.raisonSocial }}</h2>
</div>

<div class="page-content mb-3">
  <!-- signataire -->
  <ng-container
    *ngIf="currentSigner && [EnumTypeSignataire.SIGNATAIRE_EXTERNE, EnumTypeSignataire.SOLIDAIRE_EXTERNE].includes(currentSigner.type)">
    <!--Porcedure en cours: l'utilisateur n'a pas encore signé-->
    <ng-container *ngIf="
        currentProcedure?.statut === EnumProcedureSignatureStatut.EN_COURS &&
        currentSigner?.signature?.statut === EnumStatutSignature.A_SIGNER
      ">
      <div class="card">
        <ng-container *ngFor="let signataire of currentProcedure?.signataires">
          <div class="card--info card--blue mb-3" *ngIf="signataire?.approbation">
            <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
            <p>
              Ce contrat a été {{ signataire.approbation.statut === 'DOCUMENTS_CONTRAT_APPROUVES' ? 'verifié' :
              'contesté' }} par
              {{ signataire.nom }}.
              <span *ngIf="signataire?.approbation?.commentaire">
                Vous pouvez consulter son commentaire <a class="link" (click)="openModalCommentaire(signataire.approbation.commentaire)">ici</a>
              </span>
            </p>
          </div>
        </ng-container>

        <div class="card--info card--yellow">
          <lib-pxl-icon class="icon" icon="icon-alert"></lib-pxl-icon>
          Vous rencontrez des difficultés ou vous souhaitez ne pas signer électroniquement votre contrat? Nous vous
          invitons à contacter
          votre chargé(e) d’affaires.
        </div>

        <div class="ds-input-group ds-input-group--date">
          <label for="dateEcheance" class="ds-input-group__label"> Échéance <lib-asterisk></lib-asterisk> </label>
          <p class="ds-input-group__label info-text">La date de fin de validité a été fixée automatiquement à 4 mois
            après la date DPM.</p>
          <span class="ds-input-group__input">
            <input id="dateEcheance" matInput [value]="dateEcheance" [matDatepicker]="dateEcheancePicker"
              class="ds-input-group__input" placeholder="JJ/MM/AAAA" disabled />
            <mat-datepicker-toggle matSuffix [for]="dateEcheancePicker"></mat-datepicker-toggle>
            <mat-datepicker #dateEcheancePicker></mat-datepicker>
          </span>
        </div>
        <lib-signature-contrat-frame [currentSigner]="currentSigner" [currentProcedure]="currentProcedure"
          [youSignUiId]="youSignUiId">
        </lib-signature-contrat-frame>
      </div>
    </ng-container>

    <!-- Procédure en cours: l'utilisateur a signé -->
    <ng-container *ngIf="
    currentProcedure?.statut === EnumProcedureSignatureStatut.EN_COURS &&
    currentSigner?.signature?.statut === EnumStatutSignature.TERMINEE
  ">
      <div class="card">
        <div>
          <h3>Félicitations {{ utilisateur.prenom | titlecase }}!</h3>
          <h3>Vos documents ont bien été signés.</h3>
        </div>
        <div></div>
        <div class="mt-4">
          <h6>Télécharger vos documents signés</h6>
          <p class="text-gray-700">
            Vos documents seront disponibles 24h après que tous les signataires auront finalisé leur procédure de
            signature.
          </p>
        </div>
      </div>
    </ng-container>
    <!-- Procédure terminée -->
    <div class="card" *ngIf="
        currentProcedure?.statut === EnumProcedureSignatureStatut.TERMINEE &&
        currentSigner?.signature?.statut === EnumStatutSignature.TERMINEE
      ">
      <ng-container *ngIf="!showSignedDocuments; else signedDocList">
        <div>
          <h3>Félicitations {{ utilisateur.prenom | titlecase }}!</h3>
          <h3>Vos documents ont bien été signés.</h3>
        </div>
        <div></div>
        <div class="mt-4">
          <h6>Télécharger vos documents signés</h6>
          <p class="text-gray-700">
            Vos documents seront disponibles 24h après que tous les signataires auront finalisé leur procédure de
            signature.
          </p>
        </div>
      </ng-container>
      <!-- else -->
      <ng-template #signedDocList>
        <div>
          <h3 class="section-card-header">Vos documents</h3>
          <h4 class="section-card-subheader">Téléchargez dès à présent vos documents signés</h4>
        </div>
        <div class="mt-2">
          <lib-contrat-document-list [currentProcedure]="currentProcedure"
            [syntheseStructure]="syntheseStructure"></lib-contrat-document-list>
        </div>
      </ng-template>
    </div>

    <!-- historique des procedures de signature -->
    <ng-container *ngIf="proceduresHistory?.length && utilisateur && projet">
      <div class="mt-4">
        <lib-procedure-signature-contrat-history [utilisateur]="utilisateur" [proceduresHistory]="proceduresHistory"
          [projet]="projet"></lib-procedure-signature-contrat-history>
      </div>
    </ng-container>
  </ng-container>

  <!-- suiveur -->
  <ng-container *ngIf="currentSigner && [EnumTypeSignataire.SUIVEUR_EXTERNE].includes(currentSigner.type)">
    <app-signature-contrat-approbation></app-signature-contrat-approbation>
  </ng-container>
</div>