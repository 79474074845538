import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments-candidat/environment';
import { FicheDemandeAide } from '@shared-ui';
import { EnumStatutDemandeAide } from 'libs/shared-ui/src/lib/shared/enums/enum.statut-demande-aide';
import { FicheDemandeAideStatut } from 'libs/shared-ui/src/lib/shared/models/fiche-demande-aide-statut.model';

@Injectable()
export class FicheDemandeAideService {
  ficheDemandeAideUrl = environment.apiUrl + 'fiches-demande-aide';

  constructor(private httpClient: HttpClient) {}

  saveFicheDemandeAide(idStructure: string, fiche: FicheDemandeAide): Observable<HttpResponse<FicheDemandeAide>> {
    return this.httpClient.put<FicheDemandeAide>(this.ficheDemandeAideUrl + '/structures/' + idStructure, JSON.stringify(fiche), {
      observe: 'response',
    });
  }

  generateFicheDemandeAide(idStructure: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(this.ficheDemandeAideUrl + '/structures/' + idStructure + '/pdf', '', {
      observe: 'response',
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
      },
    });
  }

  getStatutFicheDemandeAide(idStructure: string): Observable<HttpResponse<EnumStatutDemandeAide>> {
    return this.httpClient.get<EnumStatutDemandeAide>(this.ficheDemandeAideUrl + '/structures/' + idStructure + '/statut', {
      observe: 'response',
    });
  }

  getStatutsFichesDemandeAideByProjetId(idProjet: string): Observable<HttpResponse<FicheDemandeAideStatut[]>> {
    return this.httpClient.get<FicheDemandeAideStatut[]>(this.ficheDemandeAideUrl + '/projets/' + idProjet + '/statuts', {
      observe: 'response',
    });
  }

  getFicheDemandeAideByStructureId(idStructure: string): Observable<HttpResponse<FicheDemandeAide>> {
    return this.httpClient.get<FicheDemandeAide>(this.ficheDemandeAideUrl + '/structures/' + idStructure, {
      observe: 'response',
    });
  }
}
