import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Aap, MembreEquipe, Structure } from '@shared-ui';

@Component({
  selector: 'app-projet-consortium-info-equipe',
  templateUrl: './projet-consortium-info-equipe.component.html',
  styleUrls: ['../../projet-consortium-info.component.scss'],
})
export class ProjetConsortiumInfoEquipeComponent {
  @Input() aap: Aap;
  @Input() membresEquipe: MembreEquipe[];
  @Input() isProjectUpdatable: boolean;
  @Input() consortiumAccessGranted: boolean;
  @Input() structure: Structure;
  @Input() responsableIsValid: boolean;

  constructor(private router: Router, private route: ActivatedRoute) {}

  /**
   * Permet d'aller a la page modification d'un membre
   * @param membre, membre à consulter ou modifier
   */
  goToMembreEquipe(index: string): void {
    this.router.navigate(['projet-consortium-membre-equipe/' + index], { relativeTo: this.route });
  }

  /**
   * Permet d'aller a la page d'ajout d'un membre
   * @param membre, membre à consulter ou modifier
   */
  addMembreEquipe(): void {
    if (this.isProjectUpdatable && this.consortiumAccessGranted) {
      this.router.navigate(['projet-consortium-membre-equipe/'], { relativeTo: this.route });
    }
  }

  roleEquipeExist(membre: MembreEquipe): boolean {
    return !!membre.infosProjet?.roleEquipe;
  }

  isMembreMandatory(): boolean {
    return this.aap?.equipeObligatoire && this.membresEquipe?.length === 0;
  }

  isMembreOptional(): boolean {
    return !this.aap?.equipeObligatoire || this.membresEquipe?.length !== 0;
  }
}
