import { EnumClassificationSaisie } from '@shared-ui';
import { DisplayColumn } from '@shared-ui';

export const DEBUT_TEXT =
  "Mois de début de l'activité (mois inclus). Idéalement, le début et la fin de la tâche doivent appartenir à une même période selon le calendrier défini.";
export const FIN_TEXT =
  "Mois de fin de l'activité (mois inclus). Idéalement, le début et la fin de la tâche doivent appartenir à une même période selon le calendrier défini.";
export const LIVRABLE_TEXT =
  "Libre avec indication contextuelle : Numéro du livrable auquel est rattachée l'activité élémentaire de la ligne de la base de données.";
export const CAT_1_TEXT = 'Nombre de personnes.mois de la catégorie 1 (ingénieurs et cadres)';
export const CAT_2_TEXT = 'Nombre de personnes.mois de la catégorie 2 (techniciens supérieurs et agents de maîtrise)';
export const CAT_3_TEXT = 'Nombre de personnes.mois de la catégorie 3 (employés administratifs et ouvriers)';
export const SOUS_TRAITANCE_TEXT = 'Dépenses de sous-traitance';
export const AMORTISSEMENT_TEXT = "Contribution aux amortissements au prorata du taux d'utilisation pour le projet";
export const AUTRE_TEXT = 'Autres achats (consommables, équipements non amortis...)';
export const INVESTISSEMENT_TEXT =
  "Valeur brute des immobilisations, ventilées le cas échéant, selon les acomptes et autres tranches d'investissement";
export const CLASSIFICATION_TEXT =
  "Proposition de Classification de l'activité (menu déroulant)\nRI : Recherche industrielle\nDE : Développement expérimental\nADM : Lot de management\nEnvironnement : Protection de l'environnement\nAutre : Au sens de l'encadrement des aides d'État";

export const COLUMN_ID = [
  'lot',
  'tache',
  'intitule_tache',
  'structure',
  'debut',
  'fin',
  'livrable',
  'salaire_cat_1',
  'salaire_cat_2',
  'salaire_cat_3',
  'sous_traitance',
  'amortissement',
  'autre',
  'investissement',
  'classification',
  'total_personne',
  'duree_tache',
  'salaires',
  'frais_generaux',
  'cout_presentes',
];

export const DISPLAY_COLUMN: readonly DisplayColumn[] = [
  {
    def: 'lot',
    label: 'Lot',
    hide: false,
    mandatory: true,
    wrap: false,
    addAction: true,
    filterEnable: true,
    span: true,
    width: '110px',
    sticky: true,
    cellInfo: 'lotName',
  },
  {
    def: 'indexTache',
    label: 'Tâche',
    hide: false,
    mandatory: false,
    wrap: false,
    addAction: true,
    filterEnable: true,
    width: '110px',
    editable: false,
    sticky: true,
  },
  {
    def: 'tacheName',
    label: 'Intitule tâche',
    hide: false,
    mandatory: true,
    wrap: false,
    addAction: false,
    filterEnable: true,
    width: '305px',
    editable: true,
  },
  {
    def: 'structureName',
    label: 'Structure',
    hide: false,
    mandatory: false,
    wrap: false,
    addAction: false,
    filterEnable: true,
    width: '170px',
  },
  {
    def: 'tacheDebut',
    label: 'Mois début tâche',
    hide: false,
    mandatory: true,
    wrap: true,
    addAction: false,
    filterEnable: false,
    width: '100px',
    editable: true,
    info: DEBUT_TEXT,
  },
  {
    def: 'tacheFin',
    label: 'Mois fin tâche',
    hide: false,
    mandatory: true,
    wrap: true,
    addAction: false,
    filterEnable: false,
    width: '100px',
    editable: true,
    info: FIN_TEXT,
  },
  {
    def: 'livrable',
    label: 'Livrable',
    hide: false,
    mandatory: false,
    wrap: false,
    addAction: false,
    filterEnable: false,
    width: '160px',
    editable: true,
    info: LIVRABLE_TEXT,
  },
  {
    def: 'personnelCat1',
    label: 'Personne.mois cat1',
    hide: false,
    mandatory: true,
    wrap: true,
    addAction: false,
    filterEnable: false,
    width: '135px',
    editable: true,
    total: true,
    info: CAT_1_TEXT,
  },
  {
    def: 'personnelCat2',
    label: 'Personne.mois cat2',
    hide: false,
    mandatory: true,
    wrap: true,
    addAction: false,
    filterEnable: false,
    width: '135px',
    editable: true,
    total: true,
    info: CAT_2_TEXT,
  },
  {
    def: 'personnelCat3',
    label: 'Personne.mois cat3',
    hide: false,
    mandatory: true,
    wrap: true,
    addAction: false,
    filterEnable: false,
    width: '135px',
    editable: true,
    total: true,
    info: CAT_3_TEXT,
  },
  {
    def: 'sousTraitance',
    label: 'Sous-traitance',
    hide: false,
    mandatory: false,
    wrap: false,
    addAction: false,
    filterEnable: false,
    width: '160px',
    editable: true,
    defNote: 'sousTraitanceDescription',
    total: true,
    info: SOUS_TRAITANCE_TEXT,
  },
  {
    def: 'amortissement',
    label: 'Contribution aux amortissement',
    hide: false,
    mandatory: false,
    wrap: true,
    addAction: false,
    filterEnable: false,
    width: '170px',
    editable: true,
    defNote: 'amortissementDescription',
    total: true,
    info: AMORTISSEMENT_TEXT,
  },
  {
    def: 'autre',
    label: 'Autre dépense',
    hide: false,
    mandatory: false,
    wrap: false,
    addAction: false,
    filterEnable: false,
    width: '170px',
    editable: true,
    defNote: 'autreDescription',
    total: true,
    info: AUTRE_TEXT,
  },
  {
    def: 'investissement',
    label: 'Investissement',
    hide: false,
    mandatory: false,
    wrap: false,
    addAction: false,
    filterEnable: false,
    width: '170px',
    editable: true,
    defNote: 'investissementDescription',
    total: true,
    info: INVESTISSEMENT_TEXT,
  },
  {
    def: 'classification',
    label: 'Classification Saisie',
    hide: false,
    mandatory: false,
    wrap: false,
    addAction: false,
    filterEnable: true,
    width: '170px',
    selectableEnable: Object.keys(EnumClassificationSaisie),
  },
  {
    def: 'totalPersonne',
    label: 'Total personne. mois',
    hide: false,
    mandatory: false,
    wrap: false,
    addAction: false,
    filterEnable: false,
    width: '170px',
    calculated: true,
    total: true,
  },
  {
    def: 'tacheDuree',
    label: 'Durée tâche',
    hide: false,
    mandatory: false,
    wrap: false,
    addAction: false,
    filterEnable: true,
    width: '170px',
    calculated: true,
    total: true,
  },
  {
    def: 'salaires',
    label: 'Salaires présentés',
    hide: false,
    mandatory: false,
    wrap: true,
    addAction: false,
    filterEnable: false,
    width: '170px',
    calculated: true,
    total: true,
  },
  {
    def: 'frais',
    label: 'Frais généraux présentés',
    hide: false,
    mandatory: false,
    wrap: true,
    addAction: false,
    filterEnable: false,
    width: '170px',
    calculated: true,
    total: true,
  },
  {
    def: 'sousTraitancePresentes',
    label: 'Sous-traitance présentée',
    hide: false,
    mandatory: false,
    wrap: true,
    addAction: false,
    filterEnable: false,
    width: '160px',
    calculated: true,
    total: true,
  },
  {
    def: 'amortissementPresentes',
    label: 'Contribution aux amortissements présentés',
    hide: false,
    mandatory: false,
    wrap: true,
    addAction: false,
    filterEnable: false,
    width: '170px',
    calculated: true,
    total: true,
  },
  {
    def: 'autrePresentes',
    label: 'Autres dépenses présentées',
    hide: false,
    mandatory: false,
    wrap: true,
    addAction: false,
    filterEnable: false,
    width: '170px',
    calculated: true,
    total: true,
  },
  {
    def: 'cout',
    label: 'Coûts présentés',
    hide: false,
    mandatory: false,
    wrap: false,
    addAction: false,
    filterEnable: false,
    width: '170px',
    calculated: true,
    total: true,
  },
];
