import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { EnqueteRadioToggleModule } from '@shared-candidat/components/enquete-radio-toggle/enquete-radio-toggle.module';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { CustomDateAdapter, SharedUiModule } from '@shared-ui';
import { ProjetConsortiumMembreEquipeComponent } from './projet-consortium-membre-equipe.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, SharedUiModule, MatSlideToggleModule, MaterialModule, EnqueteRadioToggleModule],
  declarations: [ProjetConsortiumMembreEquipeComponent],
  exports: [ProjetConsortiumMembreEquipeComponent],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class ProjetConsortiumMembreEquipeModule {}
