import { EnqueteRpInterface } from '../interfaces/enqueteRp.interface';

export class EnqueteRP implements EnqueteRpInterface {
  situationProfessionnelle: string;
  emploiSecteurPublic: string;
  organismeRecherche: string;
  financementsPublic: boolean;
  typeFinancementsPublic: string;
  autreSituationProfessionnelle: string;
  detailEmploiActuel: string;
  niveauformation: string;
  anneeObtentionDiplome: string;
  detailDiplome: string;
  autreDiplome: string;
  dejaCandidat: boolean;
  anneeDepotCandidature: string;
  laureatPEPITETEE: boolean;
  anneeLaureatPEPITETEE: string;
  laureatEmergenceOuBeneficieBourse: boolean;
  anneeLaureatEmergenceOuBeneficieBourse: string;
  entrepriseCree: boolean;
  raisonSocialeEntrepriseCree: string;
  dateCreationEntrepriseCree: Date;
  sirenEntrepriseCree: string;
  laureatEmergenceAutreProjet: boolean;
  anneeLaureatEmergenceAutreProjet: string;
  laureatCreationDev: boolean;
  anneeLaureatCreationDev: string;
  entrepriseCreeAutreProjet: Date;
  raisonSocialeEntrepriseCreeAutreProjet: string;
  dateCreationEntrepriseCreeAutreProjet: boolean;
  sirenEntrepriseCreeAutreProjet: string;
  acceptTransmissionConcoursNETVA: boolean;
}
