import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function bicValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const bicPattern = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

    const valid = bicPattern.test(control.value);

    return valid ? null : { invalidBic: { value: control.value } };
  };
}
