import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@shared-candidat/shared.module';
import { SharedUiModule } from '@shared-ui';
import { ProjetInfoDepensesCalendrierComponent } from './projet-info-depenses-calendar.component';

@NgModule({
  declarations: [ProjetInfoDepensesCalendrierComponent],
  imports: [
    CommonModule,
    SharedUiModule,
    SharedModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatTooltipModule,
    MatToolbarModule,
    MatButtonModule,
    MatDatepickerModule,
    MatTableModule,
    MatIconModule,
  ],
  exports: [ProjetInfoDepensesCalendrierComponent],
})
export class ProjetInfoDepensesCalendrierModule {}
