import { NgModule } from '@angular/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedUiModule } from '@shared-ui';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { ProjetConsortiumInformationBancaireComponent } from '@features-candidat/projet-creation/component/projet-consortium-information-bancaire/projet-consortium-information-bancaire.component';
import { SharedModule } from '@shared-candidat/shared.module';
import { InformationBancaireModalModule } from '@shared-candidat/components/modals/information-bancaire-modal/information-bancaire-modal.module';

@NgModule({
  declarations: [ProjetConsortiumInformationBancaireComponent],
  imports: [
    SharedModule,
    SharedUiModule,
    MaterialModule,
    InformationBancaireModalModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
  exports: [ProjetConsortiumInformationBancaireComponent],
})
export class ProjetConsortiumInformationBancaireModule {}
