import { HttpResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@environments-candidat/environment';
import { ProjetInfoService } from '@features-candidat/projet-creation/service/projet-info.service';
import { AapService } from '@services-candidat/aap.service';
import { ContractualisationService } from '@services-candidat/contractualisation.service';
import { ProjetService } from '@services-candidat/projet.service';
import {
  Aap,
  DemandeModificationHelperService,
  EnumAapStatut,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumProjetTag,
  EnumTypePartenaire,
  EnumTypeWorkflow,
  Projet,
  ProjetEtape,
  ShowToastrService,
  Structure,
  Utilisateur,
} from '@shared-ui';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import { UserService } from '@services-candidat/user.service';
import { combineLatest, tap } from 'rxjs';
import { StructureService } from '@services-candidat/structure.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pxl-sidenav-projet',
  templateUrl: './sidenav.projet.component.html',
  styleUrls: ['./sidenav.projet.component.scss'],
})
export class SidenavProjetComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);

  projet: Projet = new Projet();
  aap: Aap = new Aap();
  structures: Structure[] = [];
  emailString = '';
  originUrl: string;
  modificationTag: string;
  canUserSeeContract = false;
  canUserSeeProjet = false;
  canUserSeeStructure = false;
  private user: Utilisateur;

  constructor(
    private projetService: ProjetService,
    private aapService: AapService,
    private showToastrService: ShowToastrService,
    private projetInfoService: ProjetInfoService,
    private demandeModificationHelperService: DemandeModificationHelperService,
    private contractualisationService: ContractualisationService,
    private sharedFunction: SharedFunction,
    private userService: UserService,
    private structureService: StructureService,
    private route: ActivatedRoute
  ) {
    this.updateProjet();
  }

  ngOnInit(): void {
    this.originUrl = environment.docPublicBaseUrl;
    combineLatest([this.getProjet(), this.getAap(), this.getUtilisateur(), this.loadStructures()])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.canUserSeeProjet = this.sharedFunction.canUserReadProject(this.user, this.projet);
        this.canUserSeeStructure = this.sharedFunction.canUserReadStructure(this.user, this.structures);
      });
    this.setCanUserSeeContractPage();
  }

  getUtilisateur() {
    return this.userService.getUserObservable().pipe(
      takeUntilDestroyed(this.destroyRef),
      tap((user: Utilisateur) => {
        this.user = user;
      })
    );
  }

  /*
   * Met à jour le nom du projet
   * */
  updateProjet(): void {
    this.projetInfoService
      .onNomProjet()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((nomProjet: string) => {
        if (nomProjet) {
          this.projet.nom = nomProjet;
        }
      });

    this.projetInfoService
      .onAcronymeProjet()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((acronymeProjet: string) => {
        if (acronymeProjet) {
          this.projet.acronyme = acronymeProjet;
        }
      });

    this.projetInfoService
      .onStatutProjet()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((statutProjet: EnumProjetStatut) => {
        if (statutProjet) {
          this.projet.statut = statutProjet;
        }
      });

    this.projet.statut = null;
    this.projet.nom = '';
  }

  /*
   * Récupérer l'aap et le projet et qui a été sélectionné
   * */
  getProjet() {
    return this.projetService.getProjetObservable().pipe(
      takeUntilDestroyed(this.destroyRef),
      tap((response: Projet) => {
        if (response) {
          this.projet = response;
          this.modificationTag = this.demandeModificationHelperService.tagValeur(this.projet);
        }
      })
    );
  }

  getAap() {
    let stop = 0;
    return this.aapService.loadAapSubject().pipe(
      takeUntilDestroyed(this.destroyRef),
      tap((response: HttpResponse<Aap>) => {
        if (response) {
          this.aap = response.body;
          if (
            this.aap.statut === EnumAapStatut.FERME &&
            this.aap.typeWKF !== EnumTypeWorkflow.WKF1 &&
            stop === 0 &&
            this.projet.tag !== EnumProjetTag[EnumProjetTag.A_CORRIGER.toString()]
          ) {
            this.showToastrService.aapNotOuvert();
            stop = 1;
          }
          this.emailString = 'mailto:' + this.aap.bal + "?Subject=Demande d'information " + this.aap.nom;
        }
      })
    );
  }

  /*
   * Récupère la dernière étape du projet
   * */
  getEtapeProjet(): ProjetEtape {
    if (this.projet.etapes != null) {
      /* Classe les dates par ordre croissant afin de récupérer la plus récente */
      const datesEtapes = this.projet.etapes;
      datesEtapes.sort((b, a) => {
        return (new Date(b.date) as any) - (new Date(a.date) as any);
      });

      return datesEtapes[datesEtapes.length - 1];
    }

    return { nom: null, statut: null, date: null };
  }

  setCanUserSeeContractPage(): void {
    this.contractualisationService
      .canUserSeeContractPage()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((canSee: boolean) => {
        this.canUserSeeContract = canSee;
      });
  }

  ngOnDestroy(): void {
    this.showToastrService.clearToastr();
  }

  private loadStructures() {
    return this.structureService.getStructuresByIdProject(this.route.snapshot.parent.params.id).pipe(
      takeUntilDestroyed(this.destroyRef),
      tap((response: HttpResponse<Structure[]>) => {
        this.structures = response?.body;
      })
    );
  }

  protected readonly EnumProjetStatut = EnumProjetStatut;
  protected readonly EnumProjetEtape = EnumProjetEtape;
  protected readonly EnumTypePartenaire = EnumTypePartenaire;
}
