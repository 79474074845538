import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjetConsortiumCreationComponent } from './projet-consortium-creation.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared-candidat/shared.module';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, MaterialModule, SharedUiModule],
  declarations: [ProjetConsortiumCreationComponent],
  exports: [ProjetConsortiumCreationComponent],
})
export class ProjetConsortiumCreationModule {}
