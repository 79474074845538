import { enumKeys } from "../../utils/enum-utils";

export enum EnumStatutDemandeAide {
  INITIALIZED = 'INITIALIZED',
  GENERATED = 'GENERATED',
  COMPLETED = 'COMPLETED'
}

export namespace EnumStatutDemandeAide {
  export function toString(statut: EnumStatutDemandeAide): string {
    switch (statut) {
      case EnumStatutDemandeAide.INITIALIZED:
        return 'Initialisé';
      case EnumStatutDemandeAide.GENERATED:
        return 'Généré';
      case EnumStatutDemandeAide.COMPLETED:
        return 'Complet';
    }
  }

  export function all(): EnumStatutDemandeAide[] {
    return enumKeys(EnumStatutDemandeAide) as unknown as EnumStatutDemandeAide[];
  }
}
