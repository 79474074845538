import { Inject, Injectable } from '@angular/core';
import { Environment } from '../../models/environment.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SignataireInterface } from '../../interfaces/signataire.interface';

@Injectable({
  providedIn: 'root',
})
export class SignataireHttpService {
  private readonly structureUrl;
  private readonly contractualisationSignatairesPath = 'contractualisation/signataires';

  constructor(@Inject('environment') private environment: Environment, private httpClient: HttpClient) {
    this.structureUrl = this.environment.apiUrl + 'structures';
  }

  public fetchSignataires(idStructure: string): Observable<SignataireInterface[]> {
    return this.httpClient.get<SignataireInterface[]>(`${this.structureUrl}/${idStructure}/${this.contractualisationSignatairesPath}`);
  }
}
