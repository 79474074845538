export enum EnumEnqueteIncubateurPublic {
  ALSACE_SEMIA = 'Alsace - S.E.M.I.A.',
  AQUITAINE_INCUBATEUR_REGIONAL_AQUITAINE = "Aquitaine - Incubateur Régional d'Aquitaine (IRA)",
  AUVERGNE_BUSI = 'Auvergne - BUSI',
  BASSE_NORMANDIE_NORMANDIE_INCUBATION = 'Basse Normandie - Normandie Incubation',
  BOURGOGNE_FRANCHE_COMTE_DECA_BFC = 'Bourgogne-Franche-Comté DECA-BFC',
  BRETAGNE_EMERGYS = 'Bretagne - EMERGYS',
  CENTRE_ARITT_LANCEO = 'Centre - ARITT-LANCEO',
  CHAMPAGNE_ARDENNE_CARINNA = 'Champagne-Ardenne - CARINNA',
  CORSE_ADEC_INIZIA = 'Corse - ADEC - INIZIA',
  FRANCHE_COMTE_IEI_FC_INCUBATEUR_D_ENTREPRISES_INNOVANTES_DE_FRANCHE_COMTE = "Franche Comté - IEI. FC - Incubateur d'Entreprises Innovantes de Franche-Comté",
  GUYANE_TECHNOPOLE = 'Guyane Technopole',
  HAUTE_NORMANDIE_SEINARI = 'Haute-Normandie - SEINARI',
  ILE_DE_FRANCE_AGORANOV = 'Ile de France - AGORANOV',
  ILE_DE_FRANCE_INCUBALLIANCE = 'Ile de France - INCUBALLIANCE',
  ILE_DE_FRANCE_PARIS_BIOTECH_SANTE = 'Ile de France - Paris Biotech Santé',
  LANGUEDOC_ROUSSILLON_LANGUEDOC_ROUSSILLON_INCUBATION_LRI = 'Languedoc-Roussillon - Languedoc-Roussillon Incubation (LRI)',
  LIMOUSIN_AVRUL = 'Limousin - AVRUL',
  LORRAINE_INCUBATEUR_LORRAIN_IL = 'Lorraine - Incubateur lorrain (IL)',
  MIDI_PYRENEES_MIPY = 'Midi-Pyrénées - MIPY',
  NORD_PAS_DE_CALAIS_EURASANTE = 'Nord-Pas-de-Calais - EURASANTE',
  PAYS_DE_LA_LOIRE_ATLANPOLE = 'Pays de la Loire - ATLANPOLE',
  POITOU_CHARENTES_ETINCEL = 'Poitou-Charentes - ETINCEL',
  PROVENCE_ALPES_COTE_D_AZUR_BELLE_DE_MAI = "Provence Alpes Côte d'Azur - Belle de Mai",
  PROVENCE_ALPES_COTE_D_AZUR_IMPULSE = "Provence Alpes Côte d'Azur - IMPULSE",
  PROVENCE_ALPES_COTE_D_AZUR_PACA_EST = "Provence Alpes Côte d'Azur - PACA-EST",
  REUNION_TECHNOPOLE_DE_LA_REUNION = 'Réunion - Technopole de la Réunion',
  RHONE_ALPES_CREALYS = 'Rhône Alpes - CREALYS',
  RHONE_ALPES_GATE_1 = 'Rhône Alpes - GATE 1',
  AGORANOV = 'AGORANOV',
  TECHNOPOLE_DE_LA_REUNION = 'TECHNOPOLE DE LA REUNION',
  ATLANPOLE = 'ATLANPOLE',
  AVRUL = 'AVRUL',
  BELLE_DE_MAI = 'BELLE DE MAI',
  CLERMONT_AUVERGNE_INNOVATION_CAI_BUSI = 'Clermont Auvergne Innovation (CAI) - BUSI',
  DECA_BFC = 'DECA BFC',
  EMERGYS = 'EMERGYS',
  EURASANTE = 'EURASANTE',
  INCUBATEUR_LORRAIN = 'INCUBATEUR LORRAIN',
  NUBBO = 'NUBBO',
  IMPULSE = 'IMPULSE',
  INCUBALLIANCE = 'INCUBALLIANCE',
  INCUBATEUR_ACADEMIQUE_TECHNOPOLE_GRAND_POITIERS = 'Incubateur académique Technopole GRAND POITIERS',
  INITIUM = 'INITIUM',
  INIZIA = 'INIZIA',
  NORMANDIE_INCUBATION = 'NORMANDIE INCUBATION',
  PACA_EST = 'PACA-EST',
  PARIS_BIOTECH_SANTE = 'PARIS BIOTECH SANTE',
  QUEST_FOR_CHANGE = 'QUEST FOR CHANGE',
  AUTRE = 'Autre',
}
