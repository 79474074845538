export enum EnumEnqueteFinancement {
  ANR = 'ANR',
  AUCUN_FINANCEMENT = 'Aucun financement',
  BPIFRANCE = 'Bpifrance',
  CONCOURS_MONDIAL_D_INNOVATION_CMI = "Concours mondial d'innovation (CMI)",
  FUI = 'FUI',
  PIA = 'PIA',
  PROJETS_EUROPEENS = 'Projets européens',
  AUTRE = 'Autre',
}
