<section class="form-step">
  <div [class.ds-input-group--error]="radioToggle.invalid && radioToggle.touched">
    <div class="title-container">
      <div class="toggle-title-container">
        <label class="toggle-title">{{ title }}</label>
        <span class="ds-input-group__label-asterisk"> *</span>
      </div>

      <div [formGroup]="radioToggleGroup" class="align_radio">
        <label class="ds-radio-classic" [for]="id + '_false'">
          <input type="radio" class="ds-radio__input" [id]="id + '_false'" formControlName="radioToggle" [value]="false" />
          <span class="ds-radio__label"><span>Non</span></span>
        </label>
        <label class="ds-radio-classic" [for]="id + '_true'">
          <input type="radio" class="ds-radio__input" [id]="id + '_true'" formControlName="radioToggle" [value]="true" />
          <span class="ds-radio__label"><span>Oui</span></span>
        </label>
      </div>
    </div>

    <!-- Icône et message d'erreur -->
    <ng-container *ngIf="radioToggle.invalid && radioToggle.touched">
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
    </ng-container>
  </div>
  <ng-content *ngIf="radioToggle?.value === true"></ng-content>
</section>
