import { Injectable } from '@angular/core';
import { Projet } from '../models/_public_models';
import { EnumProjetTag } from '../enums/enum.projetTag';

@Injectable({
  providedIn: 'root',
})
export class DemandeModificationHelperService {
  /*
   * Récupère la valeur de l'énumération du tag de correction du projet ou structures
   * */
  tagValeur(projet: Projet): string {
    const structuresAModifier = projet.structures.filter(
      structure => structure?.tag?.toString() === EnumProjetTag[EnumProjetTag.A_CORRIGER]
    ).length;
    if (structuresAModifier || projet?.tag?.toString() === EnumProjetTag[EnumProjetTag.A_CORRIGER]) {
      return EnumProjetTag.A_CORRIGER.toString();
    }

    const structuresModifiees = projet.structures.filter(
      structure => structure?.tag?.toString() === EnumProjetTag[EnumProjetTag.CORRIGE]
    ).length;
    if (structuresModifiees || projet?.tag?.toString() === EnumProjetTag[EnumProjetTag.CORRIGE]) {
      return EnumProjetTag.CORRIGE.toString();
    }

    return '';
  }
}
