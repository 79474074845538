import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-candidat/shared.module';
import { ProjetInfoDepensesStructureComponent } from './projet-info-depenses-structure.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedUiModule } from '@shared-ui';
import { MaterialModule } from '@shared-candidat/modules/material.module';

@NgModule({
  declarations: [ProjetInfoDepensesStructureComponent],
  imports: [CommonModule, SharedModule, MaterialModule, SharedUiModule, MatTooltipModule],
  exports: [ProjetInfoDepensesStructureComponent],
})
export class ProjetInfoDepensesStructureModule {}
