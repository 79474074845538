import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { SharedUiModule } from '@shared-ui';
import { ProjetConsortiumInfoEquipeComponent } from './components/projet-consortium-info-equipe/projet-consortium-info-equipe.component';
import { ProjetConsortiumInfoComponent } from './projet-consortium-info.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, SharedUiModule, MaterialModule],
  declarations: [ProjetConsortiumInfoComponent, ProjetConsortiumInfoEquipeComponent],
  exports: [ProjetConsortiumInfoComponent],
})
export class ProjetConsortiumInfoModule {}
