import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DepensesService } from '@services-candidat/depenses.service';
import { ProjetService } from '@services-candidat/projet.service';
import { UploadDocumentService } from '@services-candidat/upload-document.service';
import { UserService } from '@services-candidat/user.service';
import {
  ConfirmModalComponent,
  CsvValidationError,
  DepenseProjetUploadErrorModalComponent,
  Depenses,
  DepensesProjetImportResultModel,
  DocumentFileManagerService,
  DocumentHelper,
  DocumentProjet,
  DocumentService,
  EnumScanDocument,
  EnumScope,
  EnumTypeDocument,
  Projet,
  ShowToastrService,
  SubscriptionDestroyerComponent,
} from '@shared-ui';
import { detect } from 'chardet';
import { from, Observable, switchMap, tap } from 'rxjs';

@Component({
  selector: 'pxl-projet-info-depenses-upload',
  templateUrl: './projet-info-depenses-upload.component.html',
  styleUrls: ['./projet-info-depenses-upload.component.scss'],
})
export class ProjetInfoDepensesUploadComponent extends SubscriptionDestroyerComponent {
  private static readonly ERROR_REGEX = /(.*) sur la ligne (\d+)/g;
  private static readonly MATCH_GROUP_REGEX = /(.*) sur la ligne (\d+)/;

  @Input() projet: Projet = new Projet();
  @Input() expenses: Depenses = new Depenses();
  @Input() documentBdd: DocumentProjet = new DocumentProjet();

  // Event emitter
  @Output() docChanged = new EventEmitter<DocumentProjet>();
  @Output() expensesChanged = new EventEmitter<Depenses>();

  readonly SAFE = EnumScanDocument[EnumScanDocument.SAFE.toString()];
  readonly UNSAFE = EnumScanDocument[EnumScanDocument.UNSAFE.toString()];

  maxSize = 20000000;

  constructor(
    public projetService: ProjetService,
    public documentService: DocumentService,
    private uploadService: UploadDocumentService,
    private documentFileManagerService: DocumentFileManagerService,
    private showToastrService: ShowToastrService,
    public depensesService: DepensesService,
    public userService: UserService,
    public matDialog: MatDialog
  ) {
    super();
  }

  onSelectDoc(files: FileList): void {
    const fileToUpload = files.item(0);
    if (fileToUpload?.size >= this.maxSize) {
      this.showToastrService.error('Le fichier à importer doit avoir une taille maximale de 10Mo.');
      return;
    }
    if (!['text/csv'].includes(fileToUpload.type)) {
      this.showToastrService.error("Le fichier à importer n'est pas au bon format (.csv)");
      return;
    }

    this.uploadDocument(fileToUpload);
  }

  getFileArrayBuffer(file: File): Observable<ArrayBuffer> {
    return from(file.arrayBuffer());
  }

  isCSVArrayBufferUTF8(arrayBuffer: ArrayBuffer): boolean {
    return detect(new Uint8Array(arrayBuffer)) === 'UTF-8';
  }

  uploadDocument(file: File) {
    if (!file) {
      return;
    }

    this.getFileArrayBuffer(file)
      .pipe(
        tap((arrayBuffer: ArrayBuffer) => {
          if (!this.isCSVArrayBufferUTF8(arrayBuffer)) {
            throw new HttpErrorResponse({
              error: {
                message:
                  "Une erreur s'est produite lors de l'import. Veuillez vérifier que votre fichier CSV est bien enregistré en .CSV UTF-8",
              },
            });
          }
        }),
        switchMap(() => this.documentService.uploadDocument(this.buildDocument(file.name), file)),
        switchMap((document: DocumentProjet) => {
          this.documentBdd = document;
          const path = DocumentHelper.getDocumentS3FilePath(document);
          return this.depensesService.postCsvDepenses(this.expenses.id, path);
        })
      )
      .subscribe({
        next: (result: DepensesProjetImportResultModel) => {
          this.showToastrService.success('Le fichier a bien été importé');
          this.docChanged.emit(this.documentBdd);
          this.expensesChanged.emit(result.depenses);
        },
        error: err => {
          if (err.status === 400 && err.error.csvValidationErrors && err.error.csvValidationErrors.length > 0) {
            this.openDialogErrorCSV(err.error.csvValidationErrors);
            this.documentBdd = null;
            this.docChanged.emit(this.documentBdd);
            return;
          }
          this.showToastrService.error(err?.error?.message);
          this.documentBdd = null;
          this.docChanged.emit(this.documentBdd);
        },
      });
  }

  private buildDocument(filename: string): DocumentProjet {
    const documentBdd = new DocumentProjet();
    documentBdd.nom = filename;
    documentBdd.projetId = this.projet.id;
    documentBdd.typeDoc = EnumTypeDocument.DEPENSES_PROJET;
    documentBdd.scope = EnumScope.PROJET;
    return documentBdd;
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isScanedDocument(document: DocumentProjet): boolean {
    if (document?.scan === this.SAFE || document?.scan === this.UNSAFE) {
      return true;
    }
    return false;
  }

  /*
   * Affiche le nom du créateur du Document
   * */
  getNomCreateurDocument(document: DocumentProjet): string {
    return DocumentHelper.getNomCreateurDocument(document);
  }

  /*
   * Download a document
   * */
  downloadDocument(document: DocumentProjet): void {
    this.documentFileManagerService.downloadDocument(document).pipe(this.takeUntilDestroyed()).subscribe();
  }

  /*
   * Affiche la modale pour supprimer un document
   * */
  onDeleteDocument(document: DocumentProjet): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        description: `<p>Êtes-vous sûr de vouloir supprimer ce fichier.</p>
                          <p>Cette action est irréversible. </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
        icon: true,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.documentService
        .delete(document)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: () => {
            this.showToastrService.success('Le fichier a bien été supprimé');
            this.documentBdd = null;
            this.docChanged.emit(this.documentBdd);
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    });
  }

  private openDialogErrorCSV(csvValidationErrors: CsvValidationError[]) {
    this.matDialog
      .open(DepenseProjetUploadErrorModalComponent, {
        data: { csvValidationErrors: csvValidationErrors },
      })
      .afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }
      });
  }
}
