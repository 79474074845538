import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureContratComponent } from './signature-contrat.component';
import { SharedUiModule } from '@shared-ui';
import { SignatureContratConsortiumComponent } from '@features-candidat/projet-creation/component/signature-contrat/signature-contrat-consortium/signature-contrat-consortium.component';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SignatureContratApprobationComponent } from '@features-candidat/projet-creation/component/signature-contrat/signature-contrat-approbation/signature-contrat-approbation.component';
import { SharedModule } from '@shared-candidat/shared.module';

@NgModule({
  declarations: [SignatureContratComponent, SignatureContratConsortiumComponent, SignatureContratApprobationComponent],
  imports: [CommonModule, SharedUiModule, SharedModule, MaterialModule],
  exports: [SignatureContratComponent],
})
export class SignatureContratModule {}
