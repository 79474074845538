export enum EnumTypeNiveauFormation {
  AUTRE = 'Autre',
  BAC = 'Bac',
  BTS_DUT = 'BTS/DUT',
  DEA_DESS_MASTER = 'DEA/DESS/Master',
  DOCTEUR = 'Docteur',
  ECOLE_DE_COMMERCE = 'Ecole de commerce',
  INGENIEUR = 'Ingénieur',
  LICENCE_MAITRISE = 'Licence/Maîtrise',
  NON_DIPLOME = 'Non diplômé',
  POST_DOC = 'Post-doc',
}

export enum EnumTypeNiveauFormationTech {
  AUTRE,
  BAC,
  BTS_DUT,
  DEA_DESS_MASTER,
  DOCTEUR,
  ECOLE_DE_COMMERCE,
  INGENIEUR,
  LICENCE_MAITRISE,
  NON_DIPLOME,
  POST_DOC,
}
