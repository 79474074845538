import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-candidat/shared.module';
import { ProjetInfoDepensesUploadComponent } from './projet-info-depenses-upload.component';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [ProjetInfoDepensesUploadComponent],
  imports: [CommonModule, SharedModule, SharedUiModule],
  exports: [ProjetInfoDepensesUploadComponent],
})
export class ProjetInfoDepensesUploadModule {}
