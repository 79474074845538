<lib-card-text
  *ngIf="showCard"
  (click)="goToProjetInfoFormEmitter.emit()"
  [isAlert]="!projetValidationFunction.infosGeneralesIsCorrect(projet)"
  [isInactive]="!isProjectUpdatableByUser"
  [inputText]="inputText"
></lib-card-text>

<div
  id="projet-div"
  class="card card--generic-content"
  *ngIf="!showCard"
  (click)="goToProjetInfoFormEmitter.emit()"
  [ngClass]="{ 'is-incomplete': !projetValidationFunction.infosGeneralesIsCorrect(projet) }"
  [class.card--inactive]="!isProjectUpdatableByUser"
>
  <div class="card__content-wrapper project-info">
    <h4 class="project-info__label">Titre du projet</h4>
    <p id="projet_info_nom" class="project-info__value" *ngIf="projet?.nom">{{ projet.nom }}</p>
    <h4 class="project-info__label">Acronyme du projet</h4>
    <p id="projet_info_acronyme" class="project-info__value" *ngIf="projet?.acronyme">{{ projet.acronyme }}</p>
    <p class="project-info__value warning" *ngIf="!projet?.acronyme">A renseigner</p>
    <h4 class="project-info__label">Description du projet</h4>
    <p id="projet_info_description" class="project-info__value" *ngIf="projet?.description">{{ projet.description }}</p>
  </div>

  <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward-ios" aria-hidden="true" *ngIf="isProjectUpdatableByUser"> </lib-pxl-icon>
</div>
