<div class="card__content mt-3">
  <h1 class="card__title">Demande de correction des informations du siège</h1>
  <div class="card-description-rectification">
    <p *ngIf="demandeRectification.auteur?.nom" class="card-description-rectification__auteur">
      Demande faite par {{ demandeRectification.auteur.nom + ' ' + demandeRectification.auteur.prenom }}
      <span *ngIf="demandeRectification.dateCreation">
        , le {{ demandeRectification.dateCreation | date : 'dd/MM/YYYY' }} à {{ demandeRectification.dateCreation | date : 'HH:mm' }}
      </span>
    </p>
    <p class="card-description-rectification__commentaire">
      {{ demandeRectification.commentaire }}
    </p>
  </div>
</div>
