import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'step-information-structure',
  templateUrl: './step-information-structure.component.html',
  styleUrls: ['./step-information-structure.component.scss'],
})
export class StepInformationStructureComponent {
  @Input() structureForm: UntypedFormGroup;
  @Input() structureEnCreation = false;
  @Input() structureIdentifiedByRET = false;
  @Output() changePage = new EventEmitter<any>();

  onNextButtonClick() {
    this.changePage.emit(true);
  }

  onPreviousButtonClick() {
    this.changePage.emit(false);
  }

  onRectificationCanceled(isCanceled: boolean) {
    if (isCanceled) {
      this.structureForm.get('adresse.demandeRectification')?.reset();
    }
  }
}
