<lib-table
  [columns]="displayedColumns"
  [dataArray]="dataSource"
  [disable]="structureForm?.status"
  (dataChange)="dataChange($event)"
  (keyPress)="onkeypress($event.event, $event.element)"
></lib-table>

<ng-container id="error-structure" *ngIf="!structureForm?.valid && structureForm?.touched && structureForm?.status === 'INVALID'">
  <div class="ds-input-group ds-input-group--error">
    <div class="ds-input-group__feedback mt-3">Merci de bien vouloir renseigner tous les champs obligatoires <lib-asterisk /></div>
  </div>
</ng-container>
