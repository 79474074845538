export enum EnumEnqueteSATT {
  SATT_AQUITAINE_SCIENCE_TRANSFERT = 'SATT Aquitaine Science Transfert',
  SATT_AXLR = 'SATT AxLR',
  SATT_CONECTUS_ALSACE = 'SATT Conectus Alsace',
  SATT_GIFT_GRENOBLE = 'SATT LINKSIUM',
  SATT_GRAND_CENTRE = 'SATT Grand Centre',
  SATT_ILE_DE_FRANCE_INNOV = 'SATT Ile-de-France Innov',
  SATT_LUTECH = 'SATT Lutech',
  SATT_NORD = 'SATT Nord',
  SATT_OUEST_VALORISATION = 'SATT Ouest Valorisation',
  SATT_PARIS_SACLAY = 'SATT Paris Saclay',
  SATT_PULSALYS = 'SATT Pulsalys',
  // TODO modifier le nom de la clé une fois le mapping back + script d'ajustement effectué
  SATT_GRAND_EST = 'SATT Sayens',
  SATT_SUD_EST = 'SATT Sud-Est',
  SATT_TOULOUSE_TECH_TRANSFER = 'SATT Toulouse Tech Transfer',
}
