import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavDepensesComponent } from './sidenav-depenses.component';
import { SharedModule } from '@shared-candidat/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SidenavDepensesComponent],
  exports: [SidenavDepensesComponent],
  providers: [],
})
export class SidenavDepensesModule {}
