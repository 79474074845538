import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { ProjetInfoGrilleImpactsComponent } from './projet-info-grille-impacts.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { GrilleImpactHttpService, SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [ProjetInfoGrilleImpactsComponent],
  providers: [GrilleImpactHttpService],
  imports: [
    CommonModule,
    MaterialModule,
    SharedUiModule,
    SharedModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
})
export class ProjetInfoGrilleImpactsModule {}
