import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NpsModalModule } from '@shared-candidat/components/modals/nps-modal/nps-modal.module';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { SharedUiModule } from '@shared-ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ProjetApercuComponent } from './projet-apercu.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SharedUiModule,
    MaterialModule,
    RouterModule,
    NpsModalModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
  declarations: [ProjetApercuComponent],
  exports: [ProjetApercuComponent],
})
export class ProjetApercuModule {}
