import { enumKeys } from "../../utils/enum-utils";

export enum EnumTypeActionnaire {
  MORAL = 'MORAL',
  PHYSIQUE = 'PHYSIQUE',
}

export namespace EnumTypeActionnaire {
  export function toString(typeActionnaire: EnumTypeActionnaire): string {
    switch (typeActionnaire) {
      case EnumTypeActionnaire.MORAL:
        return 'Moral';
      case EnumTypeActionnaire.PHYSIQUE:
        return 'Physique';
    }
  }

  export function all(): EnumTypeActionnaire[] {
    return enumKeys(EnumTypeActionnaire) as unknown as EnumTypeActionnaire[];
  }
}
