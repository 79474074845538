import { enumKeys } from '../../utils/enum-utils';

export enum EnumTypePaiement {
  PRELEVEMENT = 'PRELEVEMENT',
  VIREMENT = 'VIREMENT',
}

export namespace EnumTypePaiement {
  export function toString(operationBancaire: EnumTypePaiement): string {
    switch (operationBancaire) {
      case EnumTypePaiement.PRELEVEMENT:
        return 'Prélèvement';
      case EnumTypePaiement.VIREMENT:
        return 'Virement';
    }
  }

  export function all(): EnumTypePaiement[] {
    return enumKeys(EnumTypePaiement) as unknown as EnumTypePaiement[];
  }
}
