import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Projet } from '../../shared/models/projet.model';
import { ProjetValidationFunction } from '../../utils/projet-validation';

@Component({
  selector: 'lib-projet-infos-generales',
  templateUrl: './projet-infos-generales.component.html',
  styleUrls: ['./projet-infos-generales.component.scss'],
})
export class ProjetInfosGeneralesComponent {
  @Input() projet: Projet;
  @Input() showCard: boolean;
  @Input() isProjectUpdatableByUser: boolean;
  @Input() inputText: string;

  @Output() goToProjetInfoFormEmitter = new EventEmitter();

  constructor(public projetValidationFunction: ProjetValidationFunction) {}
}
