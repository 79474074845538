<div
  id="text_modal__container"
  class="ds-modal ds-modal-mask pxl-text-modal-container"
  aria-label="Modale d'affichage d'un texte"
  aria-describedby="modalTitle"
>
  <div class="ds-modal-centered">
    <!-- Modal Content -->
    <div class="ds-modal-content ds-card--modal">
      <!-- Header -->
      <div class="ds-modal__header">
        <h4 class="ds-modal__header-title" id="modalTitle" data-ds-focus>
          {{ modalData.title }}
        </h4>

        <button
          data-ds-dismiss
          data-ds-focus
          class="ds-modal__header-closeIcon"
          aria-label="Fermeture de la modale"
          [mat-dialog-close]="true"
        >
          <span class="ds-icon" aria-hidden="true">close</span>
        </button>
      </div>

      <!-- Body -->
      <div class="ds-modal__body" [innerHtml]="modalData.description"></div>
    </div>
  </div>
</div>
