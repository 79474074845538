import { Component, Input } from '@angular/core';
import { DemandeRectification } from '@shared-ui';

@Component({
  selector: 'lib-demande-rectification-card',
  templateUrl: './demande-rectification-card.component.html',
  styleUrls: ['./demande-rectification-card.component.scss'],
})
export class DemandeRectificationCardComponent {
  @Input() demandeRectification: DemandeRectification;
}
