import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ProjetService } from '@services-candidat/projet.service';
import { EnumEnqueteConnaissanceTech, Projet, Enquete } from '@shared-ui';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjetInfoEnqueteHelperService {
  constructor(public projetService: ProjetService, public fb: FormBuilder) {}

  /**
   * Vérifie si la checkbox est checked ou pas
   * @param item valeur de la checkbox a vérifier
   * @param control control contenant les valeurs de checkboxs
   * @returns true si l'item est dans le control, false sinon
   */
  isCheckboxChecked(item: string, control: AbstractControl): boolean {
    if (control.value === undefined) {
      control.setValue([]);
    }
    return control.value?.includes(item);
  }

  /**
   * Enable ou disable le formulaire selon le choix du toggle
   * @param formGroup Form group où le champ "oui/non" a été modifié
   */
  toggleValidation(formGroup: FormGroup): void {
    for (const controlName in formGroup.controls) {
      // On ne change pas la valeur du validator de radioToggle
      if (controlName === 'radioToggle') {
        continue;
      }

      if (formGroup.controls['radioToggle'].value) {
        formGroup.controls[controlName].enable();
      } else {
        formGroup.controls[controlName].disable();
        formGroup.controls[controlName].setValue(undefined);
      }
    }
  }

  /**
   * Enable ou disable le champ "quel site" selon le premier choix
   * @param connaissanceFormGroup Form group de la section "connaissance"
   */
  toggleSite(connaissanceFormGroup: AbstractControl): void {
    if (connaissanceFormGroup.get('value').value === EnumEnqueteConnaissanceTech[EnumEnqueteConnaissanceTech.SITE_INTERNET]) {
      connaissanceFormGroup.get('site').enable();
    } else {
      connaissanceFormGroup.get('site').disable();
    }
  }

  /**
   * Enregistre l'enquête du projet si elle est valide
   * @param projetId ID du projet
   * @param enqueteForm FormGroup enquete
   * @returns Observable PUT du service projetService::upsertEnqueteProjet
   */
  saveEnqueteProjet(projetId: string, enqueteForm: FormGroup): Observable<HttpResponse<Projet>> {
    enqueteForm.markAllAsTouched();

    if (!enqueteForm.valid) {
      throw new Error('Merci de bien vouloir renseigner les champs surlignés en rouge.');
    }

    const enquete = this.getEnqueteFromForm(enqueteForm);

    return this.projetService.upsertEnqueteProjet(projetId, enquete);
  }

  /**
   * Retourne une enquete à partir du formulaire
   * @param enqueteForm FormGroup d'enquete
   * @returns Enquete
   */
  getEnqueteFromForm(enqueteForm: FormGroup): Enquete {
    const enquete: Enquete = new Enquete();

    enquete.sourceInfo = enqueteForm.controls['connaissance'].get('value').value;
    enquete.sourceInfositeWeb = enqueteForm.controls['connaissance'].get('site').value;

    enquete.hasExploitRecherchePublic = enqueteForm.controls['recherchePublique'].get('radioToggle').value;
    enquete.exploitRecherchePublicOrganisme = enqueteForm.controls['recherchePublique'].get('value').value;
    enquete.exploitRecherchePublicOrganismeCoordonnees = enqueteForm.controls['recherchePublique'].get('description').value;

    enquete.hasBrevetDepotPublic = enqueteForm.controls['etablissementPublic'].get('value').value;

    enquete.lieRecherchePublic = enqueteForm.controls['autreRecherchePublique'].get('radioToggle').value;
    enquete.lieRecherchePublicDetail = enqueteForm.controls['autreRecherchePublique'].get('value').value;

    enquete.incubeIncubateurPublic = enqueteForm.controls['incubateurPublic'].get('radioToggle').value;
    enquete.incubateurPublicDetail = enqueteForm.controls['incubateurPublic'].get('value').value;

    enquete.incubeIncubateurPrive = enqueteForm.controls['incubateurPrive'].get('radioToggle').value;
    enquete.incubateurPriveDetail = enqueteForm.controls['incubateurPrive'].get('value').value;

    enquete.matureSATT = enqueteForm.controls['satt'].get('radioToggle').value;
    enquete.sattdetail = enqueteForm.controls['satt'].get('value').value;

    enquete.objetFinancement = enqueteForm.controls['financement'].get('radioToggle').value;
    enquete.objetFinancementType = enqueteForm.controls['financement'].get('value').value;

    enquete.developpementDurable = enqueteForm.controls['developpementDurable'].get('radioToggle').value;
    enquete.developpementDurableEnergiesRenouv = enqueteForm.controls['developpementDurable'].get('energiesRenouvelables').value;
    enquete.developpementDurableGestionPollution = enqueteForm.controls['developpementDurable'].get('gestionPollution').value;
    enquete.developpementDurableOptimConsommation = enqueteForm.controls['developpementDurable'].get('optimisationConsommations').value;

    return enquete;
  }
}
