export enum EnumEnqueteRecherchePublique {
  AUTRE_ETABLISSEMENT_ENSEIGNEMENT_SUPERIEUR = "Autre établissement d'enseignement supérieur",
  BRGM = 'BRGM',
  CEA = 'CEA',
  CEMAGREF = 'CEMAGREF',
  CIRAD = 'CIRAD',
  CNES = 'CNES',
  CNRS = 'CNRS',
  IFREMER = 'IFREMER',
  INRA = 'INRA',
  INRETS = 'INRETS',
  INRIA = 'INRIA',
  INSERM = 'INSERM',
  INSTITUT_CURIE = 'Institut Curie',
  INSTITUT_PASTEUR = 'Institut Pasteur',
  IRD = 'IRD',
  LCPC = 'LCPC',
  UNIVERSITE = 'Université',
  AUTRE = 'Autre',
}
