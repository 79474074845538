import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-candidat/shared.module';
import { StepInformationStructureComponent } from './step-information-structure.component';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, MatSelectCountryModule.forRoot('fr'), SharedUiModule],
  declarations: [StepInformationStructureComponent],
  exports: [StepInformationStructureComponent],
  providers: [],
})
export class StepInformationStructureModule {}
