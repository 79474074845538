import { Calendrier, DepensesInterface, EnumStatutScenario, EnumValidation, StructuresBDD } from '@shared-ui';
import { LotModel } from './lot.model';

export class Depenses implements DepensesInterface {
  id: string;
  nomScenario: string;
  actif: boolean;
  projetId: string;
  statut: EnumValidation;
  statutScenario: EnumStatutScenario;
  calendrier: Calendrier;
  structures: StructuresBDD;
  lots: LotModel[];
}
