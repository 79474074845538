<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToConsortiumInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Ma Structure
  </a>
  <h2 class="page-header__title">Budget</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <form id="budget-form" class="form" [formGroup]="budgetForm" (ngSubmit)="saveBudget()">
    <section class="form-step">
      <div id="anchor1" class="form-step__anchor"></div>
      <header class="form-step__header"></header>

      <div class="form-step__content">
        <!-- Budget du projet -->
        <div id="input_besoin" class="form-step__budget ds-input-group" *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI">
          <label for="besoin" class="ds-input-group__label">Demande de financement</label>
          <div>
            <span id="non-option" class="ds-body-2--regular label-r align-bottom me-2">Non</span>
            <label for="financement" class="ds-switch ds-switch--m">
              <input type="checkbox" id="financement" formControlName="besoin" />
              <span class="slider"></span>
            </label>
            <span id="oui-option" class="ds-body-2--regular label-r align-bottom ms-2">Oui</span>
          </div>
        </div>

        <div
          class="form-step__budget ds-input-group"
          [class.ds-input-group--error]="!budgetForm.controls['montant'].valid && budgetForm.controls['montant'].touched"
          *ngIf="isDemandeFinancement"
        >
          <label for="montant" class="ds-input-group__label d-flex"
            >Budget présenté (€)<lib-asterisk *ngIf="isDemandeFinancement"></lib-asterisk>
            <div [matTooltip]="infoAide" class="d-flex align-items-center">
              <lib-pxl-icon class="icon-info ms-2" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon>
            </div>
          </label>
          <span class="ds-input-group__input">
            <input
              libPositiveIntegerOnly
              id="input-montant"
              name="montant"
              (blur)="onBlur()"
              (focus)="onFocus()"
              placeholder="Budget présenté"
              [attr.maxlength]="15"
              class="ds-input-group__input"
              formControlName="montant"
              #nameInput
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-montant" *ngIf="!budgetForm.controls['montant'].valid && budgetForm.controls['montant'].touched">
            <div
              class="ds-input-group__feedback"
              *ngIf="budgetForm.controls.montant.errors?.required && !budgetForm.controls.montant.value"
            >
              Veuillez indiquer le montant du budget de votre structure
            </div>
            <div
              class="ds-input-group__feedback"
              *ngIf="budgetForm.controls.montant.hasError('notANumber') && budgetForm.controls.montant.value"
            >
              Format incorrect
            </div>
          </ng-container>
        </div>

        <div
          *ngIf="isDemandeFinancement"
          id="input-montant-aide"
          class="form-step__montant ds-input-group"
          [class.ds-input-group--error]="
            !budgetForm.controls['montantAideDemande'].valid && budgetForm.controls['montantAideDemande'].touched
          "
        >
          <label for="montantAideDemande" class="ds-input-group__label d-flex"
            >Montant de l'aide demandée (€)<lib-asterisk></lib-asterisk>
            <div [matTooltip]="infoAideDemande" class="d-flex align-items-center">
              <lib-pxl-icon class="icon-info ms-2" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon>
            </div>
          </label>
          <span class="ds-input-group__input">
            <input
              libPositiveIntegerOnly
              id="montantAideDemande"
              name="montantAideDemande"
              (blur)="onBlur()"
              (focus)="onFocus()"
              placeholder="Montant de l'aide demandée"
              [attr.maxlength]="15"
              class="ds-input-group__input"
              formControlName="montantAideDemande"
              #nameInput
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-montantAideDemande"
            *ngIf="!budgetForm.controls['montantAideDemande'].valid && budgetForm.controls['montantAideDemande'].touched"
          >
            <div
              class="ds-input-group__feedback"
              *ngIf="budgetForm.controls.montantAideDemande.errors?.required && !budgetForm.controls.montantAideDemande.value"
            >
              Veuillez indiquer le montant de l'aide demandée
            </div>
            <div
              class="ds-input-group__feedback"
              *ngIf="budgetForm.controls.montantAideDemande.hasError('notANumber') && budgetForm.controls.montantAideDemande.value"
            >
              Format incorrect
            </div>
          </ng-container>
        </div>

        <div
          id="input-montant-total"
          class="form-step__total ds-input-group"
          [class.ds-input-group--error]="!budgetForm.controls['budgetTotal'].valid && budgetForm.controls['budgetTotal'].touched"
        >
          <label for="budgetTotal" class="ds-input-group__label d-flex"
          >Budget total de la structure (€) <lib-asterisk *ngIf="isDemandeFinancement"></lib-asterisk>
          <div [matTooltip]="infoAideTotal" class="d-flex align-items-center">
              <lib-pxl-icon class="icon-info ms-2" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon>
            </div>
          </label>
          <span class="ds-input-group__input">
            <input
              libPositiveIntegerOnly
              id="budgetTotal"
              name="budgetTotal"
              (blur)="onBlur()"
              (focus)="onFocus()"
              placeholder="Budget total de la structure"
              [attr.maxlength]="15"
              class="ds-input-group__input"
              formControlName="budgetTotal"
              #nameInput
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-budgetTotal"
            *ngIf="!budgetForm.controls['budgetTotal'].valid && budgetForm.controls['budgetTotal'].touched"
          >
            <div
              class="ds-input-group__feedback"
              *ngIf="budgetForm.controls.budgetTotal.errors?.required && !budgetForm.controls.budgetTotal.value"
            >
              Veuillez indiquer le montant de l'aide demandée
            </div>
            <div
              class="ds-input-group__feedback"
              *ngIf="budgetForm.controls.budgetTotal.hasError('notANumber') && budgetForm.controls.budgetTotal.value"
            >
              Format incorrect
            </div>
          </ng-container>
        </div>

        <!-- FIN de Budget du projet -->
      </div>
    </section>

    <div class="form-actions">
      <button id="creation_projet_btn_annuler" type="button" class="ds-btn ds-btn--secondary" (click)="onGoToConsortiumInfo()">
        Annuler
      </button>
      <button id="creation_projet_btn_ajouter" type="submit" class="ds-btn ds-btn--primary" *ngIf="isStructureUpdatableByUser">
        <span>Valider</span>
      </button>
    </div>
  </form>
</div>
