import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-card-text',
  templateUrl: './card-text.component.html',
  styleUrls: ['./card-text.component.scss'],
})
export class CardTextComponent {
  @Input() isAlert: boolean;
  @Input() isInactive: boolean;
  @Input() isReadOnly: boolean;
  @Input() inputText: string;
  @Input() descriptionText: string;
  @Input() showCheckmark: boolean;
  @Input() showCheckmarkRight: boolean;
  @Input() isSmallCard: boolean;

  @Output() clickEmitter = new EventEmitter();
}
