import { Component, computed, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';
import {
  DisplayColumn,
  EnumCategorieStructure,
  EnumTypeCout,
  InformationsStructuresBDD,
  SubscriptionDestroyerComponent,
  TableCell,
} from '@shared-ui';

@Component({
  selector: 'pxl-projet-info-depenses-structure',
  templateUrl: './projet-info-depenses-structure.component.html',
  styleUrls: ['./projet-info-depenses-structure.component.scss'],
})
export class ProjetInfoDepensesStructureComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() structureForm: UntypedFormGroup;
  @Input() set validated(value: boolean) {
    if (value) {
      this.updateDataSource();
    }
  }

  categorieTooltip =
    'PE : entreprise de petite taille\nME : entreprise de taille moyenne\nGE : grande entreprise\nLabo : laboratoire de recherche';
  typeTooltip = 'Complets pour les entreprises.\nMarginaux pour les labos.';
  catTooltip1 = 'Rémunération mensuelle moyenne, incluant les charges patronales.\nCat. 1 : Ingénieurs et cadres.';
  catTooltip2 = 'Rémunération mensuelle moyenne, incluant les charges patronales.\nCat. 2 : Techniciens supérieurs et agents de maîtrise.';
  catTooltip3 = 'Rémunération mensuelle moyenne, incluant les charges patronales.\nCat. 3 : Employés administratifs et ouvriers.';
  categoriesEnum = EnumCategorieStructure;
  enumTypeCout = EnumTypeCout;

  displayedColumns: DisplayColumn[] = [
    { def: 'name', label: 'Structure' },
    {
      def: 'categorieStructure',
      label: 'Catégorie structure',
      mandatory: true,
      selectable: {
        id: 'categorie',
        name: 'categorie',
        placeholder: 'Catégorie',
        options: this.categoriesEnum.all().map(data => data.toString()),
      },
      tooltip: this.categorieTooltip,
    },
    {
      def: 'typeCout',
      label: 'Type de coût',
      mandatory: true,
      selectable: { id: 'type', name: 'type', placeholder: 'Type de coût', options: Object.keys(this.enumTypeCout) },
      tooltip: this.typeTooltip,
    },
    { def: 'salaireCat1', label: 'Salaire Cat. 1', editable: true, tooltip: this.catTooltip1 },
    { def: 'salaireCat2', label: 'Salaire Cat. 2', editable: true, tooltip: this.catTooltip2 },
    { def: 'salaireCat3', label: 'Salaire Cat. 3', editable: true, tooltip: this.catTooltip3 },
  ];

  dataSource: {
    categorieStructure: TableCell;
    idStructure: TableCell;
    name: TableCell;
    salaireCat1: TableCell;
    salaireCat2: TableCell;
    salaireCat3: TableCell;
    typeCout: TableCell;
  }[] = [];

  form = computed(() => this.structureForm?.get('informationsStructures'));

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.updateDataSource();
  }

  private updateDataSource() {
    this.dataSource = (this.structureForm?.get('informationsStructures') as FormArray).controls.map(control => {
      const element = control.value;
      return {
        categorieStructure: new TableCell(element.categorieStructure),
        idStructure: new TableCell(element.idStructure),
        name: new TableCell(element.name),
        salaireCat1: new TableCell(element.salaireCat1),
        salaireCat2: new TableCell(element.salaireCat2),
        salaireCat3: new TableCell(element.salaireCat3),
        typeCout: new TableCell(element.typeCout),
      };
    });
  }

  dataChange($event: InformationsStructuresBDD) {
    const index = this.structureForm
      .get('informationsStructures')
      .value.findIndex((element: any) => element.idStructure === $event.idStructure);
    (this.structureForm.get('informationsStructures') as FormGroup).controls[index].patchValue($event);
    this.structureForm.get('informationsStructures').value[index] = $event;
    this.updateDataSource();
  }

  onkeypress($event: KeyboardEvent, element: InformationsStructuresBDD): boolean {
    if ($event.key) {
      if ($event.key === 'Enter') {
        this.dataChange(element);
      }
      if (/[^\d]/.test($event.key)) {
        return false;
      }
    }
    return true;
  }
}
