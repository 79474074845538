import { ProjetInfoInterface } from '../interfaces/projetInfo.interface';
import { Budget } from './budget.model';

export class ProjetInfo implements ProjetInfoInterface {
  nom: string;
  acronyme: string;
  description: string;
  budget: Budget = new Budget();
  dateModification: Date;
}
