import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContractualisationService } from '@services-candidat/contractualisation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StructureService } from '@services-candidat/structure.service';
import { EnumTypeSignataire, StructureSyntheseHelperService, SyntheseContractualisationStructure, Utilisateur } from '@shared-ui';
import { combineLatest, map, switchMap } from 'rxjs';
import { UserService } from '@services-candidat/user.service';

@Component({
  selector: 'app-signature-contrat',
  templateUrl: './signature-contrat.component.html',
  styleUrls: ['./signature-contrat.component.scss'],
})
export class SignatureContratComponent implements OnInit {
  syntheses: SyntheseContractualisationStructure[] = [];
  utilisateur: Utilisateur;
  isApproved = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    private structureService: StructureService,
    private route: ActivatedRoute,
    private contractualisationService: ContractualisationService,
    private router: Router,
    private structureSyntheseHelperService: StructureSyntheseHelperService,
    private utilisateurService: UserService
  ) {}

  ngOnInit(): void {
    this.utilisateurService
      .getUserObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(utilisateur => {
        this.utilisateur = utilisateur;
      });
    this.structureService
      .getSyntheseContractualisationStructures(this.route.snapshot.parent?.params.id)
      .pipe(
        map(response => response || []),
        switchMap(syntheses =>
          combineLatest(
            syntheses.map(synthese =>
              this.contractualisationService.canUserSeeSynthese(synthese).pipe(map(canSee => (canSee ? synthese : null)))
            )
          )
        ),
        map(syntheses => syntheses.filter(synthese => synthese !== null)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(syntheses => (this.syntheses = syntheses));
  }

  onGoToStructure(id: string): void {
    const routeUrl = `../signature-contrat/consortium/${id}`;
    this.router.navigate([routeUrl], { relativeTo: this.route });
  }

  canShowStructure(structure: SyntheseContractualisationStructure) {
    const currentProcedure = this.structureSyntheseHelperService.getCurrentProcedure(structure);
    if (currentProcedure) {
      const currentSigner = this.structureSyntheseHelperService.getCurrentSigner(currentProcedure, this.utilisateur, [
        EnumTypeSignataire.SUIVEUR_EXTERNE,
      ]);
      this.isApproved = currentSigner?.approbation !== null && currentSigner?.approbation !== undefined;
    }
    return (
      structure.proceduresSignatures.length &&
      (this.structureSyntheseHelperService.isUserSignataireInProcedureSignatures(structure.proceduresSignatures, this.utilisateur, [
        EnumTypeSignataire.SIGNATAIRE_EXTERNE,
        EnumTypeSignataire.SOLIDAIRE_EXTERNE,
      ]) ||
        this.structureSyntheseHelperService.isUserSuiveurInProcedureSignatures(
          structure.proceduresSignatures,
          this.utilisateur,
          EnumTypeSignataire.SUIVEUR_EXTERNE
        ))
    );
  }
}
