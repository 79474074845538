export enum EnumTypeSecteurPublic {
  AUTRE = 'Autre',
  CHERCHEUR = 'Chercheur',
  ENSEIGNANT = 'Enseignant',
  ENSEIGNANT_CHERCHEUR = 'Enseignant-chercheur',
}

export enum EnumTypeSecteurPublicTech {
  AUTRE,
  CHERCHEUR,
  ENSEIGNANT,
  ENSEIGNANT_CHERCHEUR,
}
