import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pxl-enquete-radio-toggle',
  templateUrl: './enquete-radio-toggle.component.html',
  styleUrls: ['./enquete-radio-toggle.component.scss'],
})
export class EnqueteRadioToggleComponent implements OnInit {
  @Input() radioToggleGroup: UntypedFormGroup;
  @Input() title: string;
  @Input() id: string;

  @Output() toggled = new EventEmitter();

  get radioToggle(): AbstractControl {
    return this.radioToggleGroup?.get('radioToggle');
  }

  ngOnInit(): void {
    this.radioToggle.valueChanges.subscribe({
      next: () => {
        this.toggled.emit(this.radioToggleGroup);
      },
    });
  }
}
