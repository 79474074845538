import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjetConsortiumSignataireComponent } from '@features-candidat/projet-creation/component/projet-consortium-signataire/projet-consortium-signataire.component';
import { SharedModule } from '@shared-candidat/shared.module';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [ProjetConsortiumSignataireComponent],
  imports: [CommonModule, SharedModule, SharedUiModule, SharedModule],
})
export class ProjetConsortiumSignataireModule {}
