import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { SharedUiModule } from '@shared-ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ProjetInfoDepensesCalendrierModule } from './projet-info-depenses-calendar/projet-info-depenses-calendar.module';
import { ProjetInfoDepensesStructureModule } from './projet-info-depenses-structure/projet-info-depenses-structure.module';
import { DepensesProjetTableComponent } from './projet-info-depenses-synthese/depenses-projet-table/depenses-projet-table.component';
import { ProjetInfoDepensesSyntheseComponent } from './projet-info-depenses-synthese/projet-info-depenses-synthese.component';
import { ProjetInfoDepensesUploadModule } from './projet-info-depenses-upload/projet-info-depenses-upload.module';
import { ProjetInfoDepensesComponent } from './projet-info-depenses.component';
import { ProjetInfoDepensesSharedService } from './projet-info-depenses-shared.service';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
@NgModule({
  declarations: [ProjetInfoDepensesComponent, ProjetInfoDepensesSyntheseComponent, DepensesProjetTableComponent],
  imports: [
    CommonModule,
    MaterialModule,
    MatCheckboxModule,
    MatDialogModule,
    MatMenuModule,
    MatTooltipModule,
    SharedModule,
    SharedUiModule,
    MatProgressSpinnerModule,
    ProjetInfoDepensesCalendrierModule,
    ProjetInfoDepensesStructureModule,
    ProjetInfoDepensesUploadModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
    MatPaginatorModule,
    MatSortModule,
    SharedModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
  ],
  providers: [ProjetInfoDepensesSharedService, { provide: OverlayContainer, useClass: FullscreenOverlayContainer }],
  exports: [],
})
export class ProjetInfoDepensesModule {}
