import { EnumGenre } from '../enums/enum.genre';
import { IdentityInterface } from '../interfaces/identity.interface';

export class Identity implements IdentityInterface {
  nom: string;
  prenom: string;
  dateNaissance: string;
  lieuNaissance: string;
  genre: EnumGenre;
  responsableProjet: boolean;
}
