export enum EnumTypeFinancePublic {
  AUTRE = 'Autre',
  CIFRE = 'CIFRE',
  ORGANISME_DE_RECHERCHE = 'Organisme de recherche',
  REGION = 'Région',
}

export enum EnumTypeFinancePublicTech {
  AUTRE,
  CIFRE,
  ORGANISME_DE_RECHERCHE,
  REGION,
}
