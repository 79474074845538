export enum EnumEnqueteRessourcesNaturelles {
  'Agriculture durable' = 'Agriculture durable',
  'Augmentation de la durée de vie des produits' = 'Augmentation de la durée de vie des produits',
  'Eco concept.serv. & prodts, mat.innov., analyse cycle de vie' = 'Eco concept.serv. & prodts, mat.innov., analyse cycle de vie',
  "Gestion de l'eau potable" = "Gestion de l'eau potable",
  'Infrastructures énergétiques' = 'Infrastructures énergétiques',
  'Recyclabilité des matériaux' = 'Recyclabilité des matériaux',
  "Réduc de l'intensité en matière de biens & services (démat.)" = "Réduc de l'intensité en matière de biens & services (démat.)",
  "Réduc de l'intensité énergétique des biens & services" = "Réduc de l'intensité énergétique des biens & services",
  "Stockage d'énergie" = "Stockage d'énergie",
}
