import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CalendrierInterface,
  DateTimeUtils,
  EnumRoleStructure,
  EtapesCles,
  InformationsStructuresBDD,
  Structure,
  StructuresBDD,
} from '@shared-ui';

export class ProjetInfoDepensesHelperService {
  static getStructuresForm(structuresBDD: StructuresBDD, structures: Structure[]): FormGroup {
    return new FormGroup({
      statut: new FormControl(structuresBDD?.statut),
      informationsStructures: new FormArray(
        structures
          ?.filter(
            structure => !structure.closed && structure.role !== EnumRoleStructure.MANDATAIRE && structure.budgetDepot?.besoin !== false
          )
          .map(structure => {
            const structureBDD = structuresBDD?.informationsStructures?.find(structureBDD => structureBDD.idStructure === structure.id);
            return this.getStructureForm(structureBDD, structure);
          }) ?? []
      ),
    });
  }
  static getStructureForm(structure: InformationsStructuresBDD, structureDTO: Structure): FormGroup {
    return new FormGroup({
      name: new FormControl(structureDTO.raisonSocial ?? structure?.name, Validators.required),
      categorieStructure: new FormControl(structure?.categorieStructure, Validators.required),
      typeCout: new FormControl(structure?.typeCout, Validators.required),
      idStructure: new FormControl(structureDTO.id),
      salaireCat1: new FormControl(structure?.salaireCat1?.value),
      salaireCat2: new FormControl(structure?.salaireCat2?.value),
      salaireCat3: new FormControl(structure?.salaireCat3?.value),
    });
  }

  static getStructuresBddDTO(form: FormGroup): StructuresBDD {
    const informationsStructuresForm: FormArray = form?.get('informationsStructures') as FormArray;
    return {
      statut: form?.get('statut')?.value,
      informationsStructures: informationsStructuresForm?.controls.map(informationsStructureForm =>
        this.getInformationsStructureBddDTO(informationsStructureForm as FormGroup)
      ),
    };
  }
  static getInformationsStructureBddDTO(informationsStructuresForm: FormGroup): InformationsStructuresBDD {
    return {
      categorieStructure: informationsStructuresForm?.get('categorieStructure')?.value,
      typeCout: informationsStructuresForm?.get('typeCout')?.value,
      idStructure: informationsStructuresForm?.get('idStructure')?.value,
      salaireCat1: { value: +informationsStructuresForm?.get('salaireCat1')?.value },
      salaireCat2: { value: +informationsStructuresForm?.get('salaireCat2')?.value },
      salaireCat3: { value: +informationsStructuresForm?.get('salaireCat3')?.value },
    };
  }

  static getCalendrierDTO(form: FormGroup): CalendrierInterface {
    return {
      dateT0: DateTimeUtils.formatToDate(form?.get('dateT0')?.value) || null,
      etapesCles: (form?.get('etapesCles') as FormArray)?.controls.map((etapeCleForm, i) =>
        this.getEtapeCleDTO(etapeCleForm as FormGroup, i)
      ),
      statut: form?.get('statut')?.value,
    };
  }

  static getEtapeCleDTO(form: FormGroup, index?: number): EtapesCles {
    return {
      id: index,
      date: DateTimeUtils.formatToDate(form?.get('date')?.value),
      nombreMois: +form?.get('nombreMois')?.value,
    };
  }

  static getCalendrierForm(calendrier: CalendrierInterface): FormGroup {
    const etapesCles = calendrier?.etapesCles ?? [];
    const etapesClesForm = new FormArray(etapesCles.map(etapeCle => this.getEtapeCleForm(etapeCle)));
    return new FormGroup({
      dateT0: new FormControl(calendrier?.dateT0, Validators.required),
      etapesCles: etapesClesForm,
      statut: new FormControl(calendrier?.statut),
    });
  }

  static getEtapeCleForm(etapeCle: EtapesCles): FormGroup {
    return new FormGroup({
      id: new FormControl(etapeCle?.id, Validators.required),
      date: new FormControl(etapeCle?.date, Validators.required),
      nombreMois: new FormControl(etapeCle?.nombreMois, Validators.required),
    });
  }
}
