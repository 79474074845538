<div class="card">
  <ng-container *ngIf="documentCcus">
    <p class="fw-bold">CCUs</p>
    <lib-document-card [document]="documentCcus" [approbation]="approbation"></lib-document-card>
  </ng-container>
  <ng-container *ngIf="structureSyntheses?.contratDocument">
    <p class="fw-bold mb-0">Contrats</p>
    <lib-document-card
      [document]="structureSyntheses.contratDocument"
      [approbation]="approbation"
      [showDownload]="structureSyntheses.contratDocument?.nom !== undefined"
    ></lib-document-card>
  </ng-container>
  <ng-container *ngIf="structureSyntheses?.mandatDocuments?.length > 0">
    <p class="fw-bold mb-0">Mandats</p>
    <lib-document-card
      *ngFor="let contratMandat of structureSyntheses.mandatDocuments"
      [document]="contratMandat"
      [approbation]="approbation"
      [showDownload]="contratMandat?.nom !== undefined"
    ></lib-document-card>
  </ng-container>

  <div>
    <div class="approbation-status">
      <span id="conteste-option" class="ds-body-2--regular label-l"> Contester </span>
      <label for="approbation-input" class="ds-switch ds-switch--m">
        <input type="checkbox" id="approbation-input" [(ngModel)]="status" />
        <span class="slider"></span>
      </label>
      <span id="approuve-option" class="ds-body-2--regular label-r"> Approver </span>
    </div>

    <div class="approbation-commentaire">
      <label for="commentaire" class="fw-bold mb-0">Commentaire</label>
      <textarea
        id="commentaire"
        name="commentaire"
        rows="5"
        cols="30"
        class="ds-input-group__textarea"
        [(ngModel)]="commentaire"
        placeholder="Saisir votre commentaire ici"
      ></textarea>
    </div>
    <div class="save-approbation">
      <button type="button" class="ds-btn ds-btn--primary" (click)="onApprobationSubmitted()">
        <span>Enregistrer</span>
      </button>
    </div>
  </div>
</div>
