import { Injectable } from '@angular/core';
import { EnumProjetStatut } from '@shared-ui';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ProjetInfoService {
  private nomProjetSubject = new Subject<string>();
  private acronymeProjetSubject = new Subject<string>();
  private statutProjetSubject = new Subject<EnumProjetStatut>();

  updateNomProjet(nom: string): void {
    this.nomProjetSubject.next(nom);
  }

  onNomProjet(): Observable<string> {
    return this.nomProjetSubject.asObservable();
  }

  updateAcronymeProjet(acronyme: string): void {
    this.acronymeProjetSubject.next(acronyme);
  }
  onAcronymeProjet(): Observable<string> {
    return this.acronymeProjetSubject.asObservable();
  }

  updateStatutProjet(statut: EnumProjetStatut): void {
    this.statutProjetSubject.next(statut);
  }

  onStatutProjet(): Observable<EnumProjetStatut> {
    return this.statutProjetSubject.asObservable();
  }
}
